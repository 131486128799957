import React, { useEffect, useState } from 'react';
import { Button, Spin, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateShopProducts, listSingleProducts } from '../../../redux/action/patient/shop';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css'
import { UploadOutlined } from '@ant-design/icons';
import { baseUrl, fileUrl } from '../../../Constant/url';

const UpdateProducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState("");
    const [loading, setLoading] = useState(false);
    const facilityData = useSelector((state) => state?.auth?.get_user);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [mediaFile, setMediaFile] = useState([]);
    const [products, setProduct] = useState([]);
    const location = useLocation();
    const id = new URLSearchParams(location.search).get('id');
    const [fileList, setFileList] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'medication_price') {
            formattedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot
            formattedValue = formattedValue.replace(/(\.\d{2})\d+/, '$1'); // Restrict to two decimal places
        } else if (name === 'manufacturer' || name === 'consume_type' || name ==='medication_title') {
            if (value.length > 50) {
                // toast.error("Character limit exceeded. Maximum 100 characters allowed.");
                return;
            }
        }

        setInputVal({ ...inputVal, [name]: formattedValue });
    };

    const getProducts = () => {
        setLoading(true)
        const successRes = (res) => {
            setLoading(false)
            setProduct(res.data);
            let data = {
                medication_title: res?.data?.medication_title,
                manufacturer: res?.data?.manufacturer,
                consume_type: res?.data?.consume_type,
                medication_price: res?.data?.medication_price
            }
            setInputVal(data);
            let imgArr = [];
            let image = res?.data?.medication_link;
            image.map((m, i) => {
                let itm =
                {
                    uid: i,
                    name: 'image.png',
                    status: 'done',
                    url: fileUrl + m,
                }
                imgArr.push(itm)
            })
            setFileList(imgArr)

        }
        const failureRes = (res) => {
            setLoading(false)
            toast.error(res?.message)
            setProduct([]);
        }
        let data = {
            medication_id: id,
        }
        dispatch(listSingleProducts(data, successRes, failureRes))
    }


    const handleCreate = () => {
        setLoading(true)
        const successRes = (res) => {
            setLoading(false)
            toast.success(res?.message)
            navigate('/facility_shop')
        }
        const failureRes = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }

        let data = {
            medication_id: id,
            medication_title: inputVal?.medication_title,
            manufacturer: inputVal?.manufacturer,
            consume_type: inputVal?.consume_type,
            medication_price: Number(inputVal?.medication_price),
        }
        if (mediaFile.length > 0) {
            data.medication_link = mediaFile
        }
        dispatch(updateShopProducts(data, successRes, failureRes))
    }

    useEffect(() => {
        if (facilityData?.user_type == 'super') {
            setIsSuperAdmin(true)
        } else {
            facilityData?.access?.forEach((itm) => {
                if (itm?.menu_name == 'Patients') {
                    setReadWritePermit(itm)
                }
            })
            setIsSuperAdmin(false)
        }
        getProducts();
    }, [isSuperAdmin, facilityData, readWritePermit?.value?.write]);

    const onChange = ({ fileList: newFileList }) => {
        let mArray = []
        newFileList.map((m) => {
            if (!m?.response) {
                if (m?.url)
                    mArray.push(m?.url.replace("https://d141484d0td5s5.cloudfront.net/", ""));
            }
            else {
                mArray.push(m?.response?.data[0])
            }
            setMediaFile(mArray)
        })
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className="row">
                        <div className="">
                            <div className="profile_card col-lg-6 col-xxl-5 mx-auto">
                                <h1 className='large_font pb-3'>Update Product</h1>
                                <div className="row g-4 d-flex align-items-center">
                                    <div className="col-md-12">
                                        <div className=" extra_padding" style={{ height: "100%" }}>
                                            <div className="row gy-3">
                                                <Spin spinning={loading}>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Upload Product image <span className='text-danger'>*</span></label>
                                                        <div className=" d-flex align-items-center">
                                                            <Upload
                                                                name='facility_media'
                                                                action={`${baseUrl}facilities/upload_media`}
                                                                fileList={fileList}
                                                                onChange={onChange}
                                                                onPreview={onPreview}
                                                                listType="picture-card"
                                                                className='pt-3 pb-2' >
                                                                <Button icon={<UploadOutlined />}></Button>
                                                            </Upload>
                                                        </div>
                                                    </div>
                                                </Spin>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Product Name <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='input_fields'
                                                        name="medication_title"
                                                        onChange={handleInputChange}
                                                        value={inputVal?.medication_title}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Manufacturer/Marketer <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='input_fields'
                                                        name="manufacturer"
                                                        onChange={handleInputChange}
                                                        value={inputVal?.manufacturer}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Consume Type <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='input_fields'
                                                        name="consume_type"
                                                        onChange={handleInputChange}
                                                        value={inputVal?.consume_type}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Price <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='input_fields'
                                                        name="medication_price"
                                                        onChange={handleInputChange}
                                                        type='number'
                                                        value={inputVal?.medication_price}
                                                    />
                                                </div>
                                                <div className="w-50 mx-auto">
                                                    <Button type='ghost' onClick={() => handleCreate()} className="common_btn mt-3">Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdateProducts;
