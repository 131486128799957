import React from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer_div">
        <span className='medium_font' style={{ color: "#104861", fontWeight:"600" }}>&copy; {currentYear} - All Rights Reserved - Colony</span>
      </div>
    </>
  )
}

export default Footer
