import { USER_EXIST, STREAM_USERS, PROVIDER_ROLES, PROVIDER_DEPARTMENTS, MEETING_CHANNEL, MEETING_TOKEN, IS_STREAM_MINIMIZE, IN_MEETING, USER_TYPE, MYUID, AUDIENCE_MEMBERS,HOST_MEMBERS } from "../type";

const INIT_STATE = {
    stream_users: [],
    host_members: [],
    audience_members: [],
    roles: [],
    departments: [],
    meetingChannel: null,
    meetingToken: null,
    is_stream_minimize:false,
    in_meeting: false,
    user_type: null,
    myUID: null
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_EXIST:
            return {
                ...state,
                is_user_exist: payload,
            };
        case STREAM_USERS:
            return {
                ...state,
                stream_users: payload,
            };
        case AUDIENCE_MEMBERS:
            return {
                ...state,
                audience_members: payload
            };
        case HOST_MEMBERS:
            return {
                ...state,
                host_members: payload,
            };
        case PROVIDER_ROLES:
            return {
                ...state,
                roles: payload,
            };
        case PROVIDER_DEPARTMENTS:
            return {
                ...state,
                departments: payload,
            };
        case MEETING_CHANNEL:
            return {
                ...state,
                meetingChannel: payload,
            };
        case MEETING_TOKEN:
            return {
                ...state,
                meetingToken: payload,
            };
        case IS_STREAM_MINIMIZE:
            return {
                ...state,
                is_stream_minimize: payload,
            };
        case IN_MEETING:
            return {
                ...state,
                in_meeting: payload,
            };
        case USER_TYPE:
            return {
                ...state,
                user_type: payload,
            };
        case MYUID:
            return {
                ...state,
                myUID: payload,
            };
        default:
            return state;
    }
};