import { CALLING_NOTIFICATION ,TASK_NOTIFICATION} from "../type.js";

const INIT_STATE = {
    notofication_array: [],
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
//    console.log(payload)
    switch (type) {
        case CALLING_NOTIFICATION:
            return {
                ...state,
                notofication_array: payload
            }
        case TASK_NOTIFICATION:
            return {
                ...state,
                task_notification: payload
            }
        default:
            return state;
    }
}
