import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { facilityHospitalDetail, providerRoles } from '../../../redux/action/provider/auth';
import { facilityHospital } from '../../../redux/action/facility/patient';
import { listSelfEmissionWizardMenu, getDoctorsList, updateSelfEmissionWizard, getSitterList, medicationSugg, getPerticularEmissionData } from '../../../redux/action/patient/form';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css'
import { FACILITY_ID } from '../../../Constant/creds';
import moment from 'moment';
import dayjs from 'dayjs';

const SelfEmissionWizardEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation().search
    const [inputVal, setInputVal] = useState([]);
    const [prnMedication, setPrnMedication] = useState("");
    const [loading, setLoading] = useState(true);
    const [hospitalList, setHospitalList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [singleList, setSingleList] = useState([]);
    const [sitterList, setSitterList] = useState([]);
    const [dateVal, setDateVal] = useState('');
    const [dateValDis, setDateValDis] = useState('');
    const [medSugg, setMedSugg] = useState([]);
    const [menuTags, setMenuTags] = useState([]);
    const [selectedMenuIds, setSelectedMenuIds] = useState([]);
    const [admissionDate, setAdmissionDate] = useState([]);
    const [menuArray, setMenuArray] = useState([]);
    const [dropDownVal, setDropDownVal] = useState({
        suicidal: '',
        hospital: '',
        medical_transportation: '',
        whendischarged: '',
        transport_back_home: '',
        menu: "",
        role: "",
        doctor: "",
        sitter: ""
    });
    const wizardId = new URLSearchParams(location).get('id');

    const userData = useSelector(state => state?.auth?.get_user);
    const rolesDetail = useSelector(state => state?.auth?.provider_roles?.data);
    const facilityData = useSelector((state) => state?.auth?.get_user);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isSuicidal, setIsSuicidal] = useState(false);

    const handleDropDownVal = (e) => {
        e.target.name === "suicidal" && e.target.value === 'Yes' ? setIsSuicidal(true) : setIsSuicidal(false);
        if (e.target.name === "role") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const id = selectedOption.getAttribute("id");
            getDoctors(id);
        }
        setDropDownVal((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
            [`${e.target.name}Text`]: e.target.options[e.target.selectedIndex].text
        }))
    }

    const getDoctors = (role) => {
        const success = (res) => {
            setDoctorList(res?.data)
        }
        const failure = (res) => {
            setDoctorList([])
        }
        let data = {
            facility_id: FACILITY_ID,
            role,
            page: 1,
            limit: 100,
            type: "all"
        }

        dispatch(getDoctorsList(data, success, failure))
    }

    const getSitters = (role) => {
        const success = (res) => {
            setSitterList(res?.data)
        }
        const failure = (res) => {
            setSitterList([])
        }
        let data = {
            facility_id: FACILITY_ID,
            provider_department: "Sitter",
            page: 1,
            limit: 100,
            type: "all"
        }

        dispatch(getSitterList(data, success, failure))
    }

    const getPerticularEmission = () => {
        const success = (res) => {
            setLoading(false)
            let data = res?.data;
            setSingleList(data)
            const dropDownValue = {
                suicidal: data?.suicidal_call_911,
                hospital: data?.hospital_id?._id,
                medical_transportation: data?.transport_to_come,
                whendischarged: data?.when_discharged_were_to_go,
                transport_back_home: data?.transport_back_home,
                menu: data?.menu?._id,
                role: data?.role_id?._id,
                doctor: data?.provider_id?._id,
                sitter: data?.select_sitter_id?._id,
            }
            let prn = { prn_medication: data?.prn_medication }
            let med = { medication: data?.medication }
            setPrnMedication(prn)
            setInputVal(med)
            setDropDownVal(dropDownValue)
            getDoctors(res?.data?.role_id?.role);
        }
        const failure = (res) => {
            setSingleList([])
        }
        let data = {
            _id: wizardId,
        }
        dispatch(getPerticularEmissionData(data, success, failure))
    }

    const handleInputChange = (newTags, t = '') => {
        if (t === '') {
            setInputVal(newTags);
        } else {
            // Ensure the clicked tag is not a duplicate
            if (!newTags.includes(t)) {
                const updatedTags = [...newTags, t];
                setInputVal(updatedTags);
            }
        }
    };

    const handleSearch = (searchValue) => {
        const lastValue = searchValue[searchValue.length - 1];
        getMedicationSugg(lastValue);
    };

    const getMedicationSugg = (medication_name) => {
        const success = (res) => {
            setMedSugg(res?.data)
        }
        const failure = () => {
            setMedSugg([])
        }
        let data = {
            medication_name
        }
        dispatch(medicationSugg(data, success, failure))
    }

    const handleInputChangePrn = (e) => {
        setPrnMedication(e.target.value)
    }

    const getActiveHospital = () => {
        const success = (res) => {
            setHospitalList(res?.data?.data)

        }
        const failure = (res) => {
            console.log(res, "failure")
        }
        dispatch(facilityHospital(success, failure))
    }

    const getMenuList = () => {
        const success = (res) => {
            setMenuList(res?.data)
            let pos = []
            menuList && menuList?.map((element, index) => (
                pos.push(element?.text)
            ))            
            setMenuArray(pos)
        }
        const failure = (res) => {
            setMenuList([])
        }
        dispatch(listSelfEmissionWizardMenu(success, failure))
    }

    useEffect(() => {
        let data = {
            page: 1,
            limit: 100
        }
        dispatch(facilityHospitalDetail(data))
        dispatch(providerRoles())
        getActiveHospital()
        getMenuList()
        getSitters()
    }, [])

    useEffect(() => {
        getPerticularEmission()
        disabledDate();
        disabledDateDis();
    }, [wizardId])

    const handleCreate = () => {
        setLoading(true)
        const successRes = (res) => {
            setLoading(false)
            toast.success(res?.message)
            navigate('/self_emission_wizard')
        }
        const failureRes = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }
        let data = {
            _id: wizardId,
            patient_id: userData?._id,
            suicidal_call_911: dropDownVal?.suicidal,
            hospital_id: dropDownVal?.hospital,
            admission_date: dateVal === '' ? singleList?.admission_date : dateVal,
            discharge_date: dateValDis === '' ? singleList?.discharge_date : dateValDis,
            transport_to_come: dropDownVal?.medical_transportation,
            when_discharged_were_to_go: dropDownVal?.whendischarged,
            transport_back_home: dropDownVal?.transport_back_home,
            menu: selectedMenuIds,
            menu_name: menuTags,
            medication: !inputVal?.medication ? inputVal : inputVal?.medication,
            prn_medication: !prnMedication?.prn_medication ? prnMedication : prnMedication?.prn_medication,
            role_id: dropDownVal?.role,
            provider_id: dropDownVal?.doctor,
            select_sitter_id: dropDownVal?.sitter,
            status: 1,
            hospital_name: dropDownVal?.hospitalText,
            role_name: dropDownVal?.roleText,
            provider_name: dropDownVal?.doctorText,
            sitter_name: dropDownVal?.sitterText,
        }
        dispatch(updateSelfEmissionWizard(data, successRes, failureRes))
    }

    useEffect(() => {
        if (facilityData?.user_type == 'super') {
            setIsSuperAdmin(true)
        } else {
            facilityData?.access?.forEach((itm) => {
                if (itm?.menu_name == 'Patients') {
                    setReadWritePermit(itm)
                }
            })
            setIsSuperAdmin(false)
        }
    }, [isSuperAdmin, facilityData, readWritePermit?.value?.write]);

    const handleDateChange = (date, dateString) => {
        setAdmissionDate(date)
        setDateVal(dateString)
    }
    const handleDateChangeDis = (date, dateString) => {
        setDateValDis(dateString)
    }

    // Define disabledDate function
    function disabledDate(current) {
        // Disable past dates
        return current && current < moment().startOf('day');
    }

    const handleMenuTags = (tags) => {
        // Extract _id and text separately from the selected options
        const ids = tags;
        const texts = tags.map(tag => menuList.find(element => element._id === tag)?.text || '');

        // Update state with the selected _ids and texts
        setSelectedMenuIds(ids);
        setMenuTags(texts);
    };

    function disabledDateDis(current) {
        
        // Disable past dates / by admission date selection
        return current && current < moment().startOf('day') || current < admissionDate;
    }



    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className="row">
                        <div className="">
                            <div className="profile_card col-lg-6 col-xxl-5 mx-auto">
                                <h1 className='large_font pb-3'>Update Self Emission Wizard</h1>
                                <div className="row g-4 d-flex align-items-center">
                                    <Spin spinning={loading} indicator={<LoadingOutlined />}>
                                        <div className="col-md-12">
                                            <div className=" extra_padding" style={{ height: "100%" }}>
                                                <div className="row gy-3">
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Suicidal</label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.suicidal} name='suicidal' onChange={handleDropDownVal}>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                        {isSuicidal ?
                                                            <>Call 911 </>
                                                            : ""}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'> Which Hospital </label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.hospital} name='hospital' onChange={handleDropDownVal}>
                                                            {hospitalList && hospitalList?.map((item, indx) => (
                                                                <option value={item?._id} key={indx}>{item?.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Admission Date </label>
                                                        {singleList?.admission_date &&
                                                            <DatePicker defaultValue={singleList && dayjs(singleList?.admission_date, 'YYYY/MM/DD')} onChange={(date, string) => handleDateChange(date, string)}
                                                                className='input_fields'
                                                                placeholder='Select date'
                                                                disabledDate={disabledDate}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Discharge Date </label>
                                                        {singleList?.discharge_date &&
                                                            <DatePicker defaultValue={singleList && dayjs(singleList?.discharge_date, 'YYYY/MM/DD')} onChange={(date, string) => handleDateChangeDis(date, string)}
                                                                className='input_fields'
                                                                placeholder='Select date'
                                                                disabledDate={disabledDateDis}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Transport to come</label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.medical_transportation} name='medical_transportation' onChange={handleDropDownVal}>
                                                            <option value="Medical transportation">Medical transportation</option>
                                                            <option value="Bus pass">Bus pass</option>
                                                            <option value="Ambulance">Ambulance</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>When Discharged Were to go</label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.whendischarged} name='whendischarged' onChange={handleDropDownVal}>
                                                            <option value="Group home">Group home</option>
                                                            <option value="Shelter">Shelter</option>
                                                            <option value="Nursing home">Nursing home</option>
                                                            <option value="Cila home">Cila home</option>
                                                            <option value="Friend house">Friend house</option>
                                                            <option value="Family house">Family house</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Transport back home</label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.transport_back_home} name='transport_back_home' onChange={handleDropDownVal}>
                                                            <option value="Medical transportation">Medical transportation</option>
                                                            <option value="Bus pass">Bus pass</option>
                                                            <option value="Ambulance">Ambulance</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Menu</label>
                                                        {/* <select className='input_fields px-2' value={dropDownVal?.menu} name='menu'
                                                            onChange={handleDropDownVal}>
                                                            {menuList && menuList?.map((item, index) => (
                                                                <option key={index} value={item?._id}>{item?.text}</option>
                                                            ))}
                                                        </select> */}
                                                        <Select
                                                            mode="multiple"
                                                            // value={menuArray}
                                                            onChange={(newTags) => { handleMenuTags(newTags) }}
                                                            style={{ width: '100%' }}
                                                            placeholder="Add menu"
                                                            className='medselector'
                                                        >
                                                            {menuList && menuList?.map((element, index) => (
                                                                <Option key={index} value={element._id}>
                                                                    {element.text}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Medication</label>
                                                        <Select
                                                            mode="tags"
                                                            value={!inputVal?.medication ? inputVal : inputVal?.medication}
                                                            onSearch={(newTags) => { handleSearch(newTags) }}
                                                            onChange={(newTags) => { handleInputChange(newTags) }}
                                                            style={{ width: '100%' }}
                                                            placeholder="Add medications"
                                                            className='medselector'
                                                        >
                                                            {medSugg && medSugg?.map((element, index) => (
                                                                <Option key={index} value={element}>
                                                                    {element}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>PRN Medication</label>
                                                        <input
                                                            className='input_fields'
                                                            name="prn_medication"
                                                            onChange={handleInputChangePrn}
                                                            value={prnMedication?.prn_medication}
                                                        />
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Choose Program</label>
                                                        <select className='input_fields px-2' value={dropDownVal?.role} name='role' onChange={handleDropDownVal}>
                                                            {rolesDetail && rolesDetail?.map((item, index) => (
                                                                <option key={index} id={item?.role} value={item?._id}>{item?.role}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Doctor</label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.doctor} name='doctor' onChange={handleDropDownVal}>
                                                            {doctorList && doctorList?.map((item, index) => (
                                                                <option key={index} value={item?._id}>{item?.first_name + " " + item?.last_name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Sitter</label>
                                                        <select className='input_fields px-2 selectFont' value={dropDownVal?.sitter} name='sitter' onChange={handleDropDownVal}>
                                                            {sitterList && sitterList?.map((item, index) => (
                                                                <option key={index} value={item?._id}>{item?.first_name + " " + item?.last_name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-50 mx-auto">
                                                        <Button type='ghost' onClick={() => handleCreate()} className="common_btn mt-3">Save</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SelfEmissionWizardEdit;
