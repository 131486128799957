import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

/***************** Facility Patient Part ********************/
export const uploadMediaFile = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'multipart/form-data' },
        url: `${baseUrl}patients/upload_media`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const createPatientAvatar = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}patients/create_avatar`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const createPatientData = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}patients/register`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// facility hospital list
export const facilityHospital = (successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_active_hospitals`,
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// fcality patient list
export const facilityPatientList = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/all_patients`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res?.data)
    } catch (error) {
        failureRes(error)
    }
}

// fcality patient delete
export const facilityPatientDelete = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/delete_patient`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// pateinet detal for view and delete
export const getFacilityPatient = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/patient_profile`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// pateinet dynamic forms titles
export const dynamicformsFields = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_all_form`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// get patient recrd
export const patientFormRecord = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_patient_form_record`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const getCPData = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_cp_data`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const patientFormsRecord = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_single_form_facility`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        if (res?.data?.status)
            successRes(res)
        else
            failureRes(res)

    } catch (error) {
        failureRes(error)
    }
}

export const getSingleForm = (data, successRes, failureRes) => async (dispatch) => {

    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/get_form_details`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// get patient recrd
export const AddPatientFormRecord = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/add_dynamic_form_record_of_patient`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const AddFormRecordOfPatient = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/add_form_record_of_patient`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// reset dynamic form 
export const ResetPatientFormRecord = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_single_form`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const ResetPatientFormsRecord = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_form_detail_on_reset`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// pateinet dynamic single form
export const dynamicSingleForms = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_single_form`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const deletePatientFormHistory = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/delete_patient_form_history`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// add family member
export const addFamilyMember = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/patient/add_family_member`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if(res?.data?.status){
            successRes(res)
        }else{
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}
// update family member
export const updateFamilyMember = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/patient/update_family_member`,
        data
    }
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successRes(response?.data);
    } else {
        failureRes(response?.data)
    }
}
// add treatment team
export const addTreatMentTeam = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/create_treatment_team`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const allReports = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_patient_all_reports`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}
// 
export const createReport = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/create_report`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const getSingleReportHistory = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_single_report_history`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}
// 
export const getTreatmentTeamData = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/check_team_members`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const updateStatusReport = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/update_report`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const getSingleFormReport = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_report_histories`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const getReportInfo = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_report_info`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const getPatientSingleReport = (data, successRes, failureRes) => async (dispatch) => {
    let token = localStorage.getItem("facility_token")
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}facilities/get_patient_single_report`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        const res = await apiCall(config, dispatch);
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}



