import React, { useEffect, useState } from 'react';
import taskVector from '../../../assets/facilityimg/task.png';
import { useDispatch } from 'react-redux';
import { listOrderProducts, updateOrderDetail } from '../../../redux/action/patient/shop';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Popconfirm, Modal, Pagination } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

const OrderList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [productlist, setProductlist] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [index, setIndex] = useState('');
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
        position: ['bottomRight'],
    });

    useEffect(() => {
        getData()
    }, [pagination.current, pagination.pageSize])

    const getData = () => {
        const successRes = (res) => {
            setProductlist(res?.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                total: res?.other?.total_count
            }));
        }
        const failureRes = (res) => {
            setProductlist([]);
        }
        let data = {
            page: pagination.current,
            limit: pagination.pageSize
        }
        dispatch(listOrderProducts(data, successRes, failureRes))
    }

    const updateStatus = (_id, val) => {
        const successRes = (res) => {
            toast.success(res?.message);
            getData();
        };
        const failureRes = (res) => {
            toast.error(res?.message);
        };
        let data = {
            _id,
            status: val,
        };
        dispatch(updateOrderDetail(data, successRes, failureRes));
    };

    const handlePageChange = (page, pageSize) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
    };

    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className={"card p-4"}>
                        <div className="row gy-4 d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-xl-3">
                                <div className="card_div" style={{ background: "#edf5fb" }}>
                                    <div className="content">
                                        <b style={{ fontFamily: "Roboto, sans-serif", color: "#104861" }} className="medium_font">
                                            Total Orders
                                        </b>
                                        <br />
                                        <b style={{ fontFamily: "Roboto, sans-serif", color: "#104861" }} className="medium_font">{productlist?.length}</b>
                                    </div>
                                    <img src={taskVector} alt="" width="40%" />
                                </div>
                            </div>
                        </div>

                        {/* Table  */}
                        <div className='w-100' style={{ overflow: "auto" }}>
                            <div className="table_width">
                                <table className='text-center table-striped mt-5 w-100'>
                                    <thead className='table_div'>
                                        <tr>
                                            <th className='text-center px-2'>#</th>
                                            <th>Order Number</th>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Payment Mode</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productlist?.length > 0 ? productlist.map((no, index) => (
                                            <tr key={index}>
                                                <td> {index + 1}</td>
                                                <td> {no.order_ref}</td>
                                                <td> {no.first_name + " " + no.last_name}</td>
                                                <td> {no.email}</td>
                                                <td> {no.phone_no}</td>
                                                <td> {no.payment_method}</td>
                                                <td> {moment.unix(no?.created_on).format("MM-DD-YYYY")}</td>
                                                <td>
                                                    <Popconfirm
                                                        title="Delete"
                                                        description="Are you sure want to delete this data?"
                                                        onConfirm={() => updateStatus(no?._id, "2")}
                                                        icon={
                                                            <QuestionCircleOutlined
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            />
                                                        }
                                                    >
                                                        <span variant="info" size="sm" className='me-2 my-1 pointer' >Delete</span>|&nbsp;&nbsp;
                                                    </Popconfirm>
                                                    <span variant="info" size="sm" className='me-2 my-1 pointer' onClick={() => { setIndex(index), setEditModal(true) }}>View</span>
                                                </td>
                                            </tr>
                                        )) : <div style={{ textAlign: "center", width: "100%", marginTop: "20px" }}>No Data Found</div>}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                {...pagination}
                                onChange={handlePageChange}
                                showQuickJumper={false}
                                position={['bottomRight']}
                                style={{ float: 'right' }}
                            />
                        </div>

                        <Modal open={editModal} footer={null} closable={false}>
                            <div className="">
                                <h1 className='large_font pb-3'>Order Detail</h1>
                                <div className="row gy-3">
                                    <div className="col-lg-12">
                                        <label className='small_font'>Username</label>
                                        <input
                                            value={productlist?.[index]?.first_name + " " + productlist?.[index]?.last_name}
                                            className="input_fields"
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label className='small_font'>Email</label>
                                        <input
                                            value={productlist?.[index]?.email}
                                            className="input_fields"
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label className='small_font'>Phone</label>
                                        <input
                                            value={productlist?.[index]?.phone_no}
                                            className="input_fields"
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label className='small_font'>Address</label>
                                        <input
                                            value={productlist?.[index]?.address + "," + productlist?.[index]?.country + "," + productlist?.[index]?.city + "," + productlist?.[index]?.zip_code}
                                            className="input_fields"
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label className='small_font'>Grand Total</label>
                                        <input
                                            value={productlist?.[index]?.grand_total}
                                            className="input_fields"
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <label className='small_font'>Payment Method</label>
                                        <input
                                            value={productlist?.[index]?.payment_method}
                                            className="input_fields"
                                            readOnly
                                        />
                                    </div>

                                    <div className="col-lg-12">
                                        <label className='small_font'>Product Details</label>
                                        <div className='w-100' style={{ overflow: "auto" }}>
                                            <div className="table_width">
                                                <table className='text-center table-striped mt-5 w-100'>
                                                    <thead className='table_div'>
                                                        <tr>
                                                            <th className='text-center px-2'>#</th>
                                                            <th className='text-center px-2'>Product Name</th>
                                                            <th className='text-center px-2'>Manufacturer</th>
                                                            <th className='text-center px-2'>Consume Type</th>
                                                            <th className='text-center px-2'>Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productlist?.[index]?.items.map((no, index) => (
                                                            <tr key={index}>
                                                                <td> {index + 1}</td>
                                                                <td> {no?.item_id?.medication_title}</td>
                                                                <td> {no?.item_id?.menufacturer}</td>
                                                                <td> {no?.item_id?.consume_type}</td>
                                                                <td> ${no?.item_id?.medication_price}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="d-flex mt-5">
                                        <button onClick={() => setEditModal(false)} className="common_btn2">Close</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>
            </div>

        </>
    )
}

export default OrderList;
