import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
import { GET_USER } from "../../type";

// family profile Data
export const familyProfile = (successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("family_token")}` },
        url: `${baseUrl}family_members/get`,
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// upload media file
export const uploadFamilyProfile = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'multipart/form-data' },
        url: `${baseUrl}family_members/upload_media`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Create Avatr image
export const createFamilyAvatar = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'application/json' },
        url: `${baseUrl}family_members/create_avatar`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// update profile Data
export const updateFamilyProfile = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("family_token")}` },
        url: `${baseUrl}family_members/update_profile`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
            dispatch({
                type: GET_USER,
                payload: res?.data?.data
            })
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Change password
export const familyChangePassword = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("family_token")}` },
        url: `${baseUrl}family_members/change_password`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res?.data)
        } else {
            failureRes(res?.data)
        }
    } catch (error) {
        failureRes(error)
    }
}