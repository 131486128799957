import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

export const listShopProducts = ( data ,successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/list_medication`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const listTaxDelivery = ( successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}common/get_common_data`,
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const listSingleProducts = ( data ,successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}common/get_medication`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const addMedicationProduct = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/add_medication`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const updateShopProducts = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/update_medication`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const updateTaxDelivery = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}common/add_common_data`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const listOrderProducts = ( data ,successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/order_list`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const updateOrderDetail = ( data ,successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/update_order`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};



