// Provider
// RIBBEN AND GRRAPH
export const PROVIDER_RIBBEN_SUCCESSS = "PROVIDER_RIBBEN_SUCCESSS"
// Provider Signup
export const FACILITY_HOSPITAL_DETAILS = "FACILITY_HOSPITAL_DETAILS"
export const PROVIDER_DEPARTMENT_DETAILS = "PROVIDER_DEPARTMENT_DETAILS"
export const PROVIDER_ROLES_DETAILS = "PROVIDER_ROLES_DETAILS"
export const PROVIDER_UPLOAD_MEDIA = "PROVIDER_UPLOAD_MEDIA"

// FAX
export const FAX_NUMBER_LIST_SUCCESS = "FAX_NUMBER_LIST_SUCCESS"
export const FAX_INOBX_SENTBOX_SUCCESS = "FAX_INOBX_SENTBOX_SUCCESS"
export const FAX_OUTBOX_SUCCESS = "FAX_OUTBOX_SUCCESS"
export const FAX_TRASH_SUCCESS = "FAX_TRASH_SUCCESS"
export const FAX_CONTACT_LIST_SUCCESS = "FAX_CONTACT_LIST_SUCCESS"
export const FAX_FILE_UPLOAD_SUCCESS = "FAX_FILE_UPLOAD_SUCCESS"
export const FAX_SEND_SUCCESS = "FAX_SEND_SUCCESS"
export const MS_LOGIN = "MS_LOGIN"
// FACILITY 
// FAX
export const FACILITY_FAX_NUMBER_LIST_SUCCESS = "FACILITY_FAX_NUMBER_LIST_SUCCESS"
export const FACILITY_FAX_INOBX_SENTBOX_SUCCESS = "FACILITY_FAX_INOBX_SENTBOX_SUCCESS"
export const FACILITY_FAX_OUTBOX_SUCCESS = "FACILITY_FAX_OUTBOX_SUCCESS"
export const FACILITY_FAX_TRASH_SUCCESS = "FACILITY_FAX_TRASH_SUCCESS"
export const Facility_FAX_CONTACT_LIST_SUCCESS = "Facility_FAX_CONTACT_LIST_SUCCESS"
export const FACILITY_FAX_FILE_UPLOAD_SUCCESS = "FACILITY_FAX_FILE_UPLOAD_SUCCESS"
export const FACILITY_FAX_SEND_SUCCESS = "FACILITY_FAX_SEND_SUCCESS"
export const FACILITY_RIBBENS = "FACILITY_RIBBENS"

/* User */
export const GET_USER = "GET_USER"

/* Patient */
export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const GET_BANNER = "GET_BANNER";

// Facility task
export const GET_FACILITY_TASK_LIST = "GET_FACILITY_TASK_LIST"
export const CALLING_NOTIFICATION = "CALLING_NOTIFICATION"
export const TASK_NOTIFICATION = "TASK_NOTIFICATION"

// Go LIVE
export const IS_LIVE = "IS_LIVE"
export const IS_MINIMIZE = "IS_MINIMIZE"
export const IS_CALL_MINIMIZE = "IS_CALL_MINIMIZE"

/* Haeder Menu */
export const HEADER_MENU = "HEADER_MENU"

export const USER_EXIST = "USER_EXIST";
export const IS_CHAT_MODAL_OPEN = "IS_CHAT_MODAL_OPEN";
export const CALANDER_EVENT = "CALANDER_EVENT";

// Streaming
export const STREAM_USERS = "STREAM_USERS"
export const PROVIDER_ROLES = "PROVIDER_ROLES"
export const PROVIDER_DEPARTMENTS = "PROVIDER_DEPARTMENTS"
export const MEETING_CHANNEL = "MEETING_CHANNEL"
export const MEETING_TOKEN = "MEETING_TOKEN"
export const IS_STREAM_MINIMIZE = "IS_STREAM_MINIMIZE"
export const IN_MEETING = "IN_MEETING"
export const USER_TYPE = "USER_TYPE"
export const MYUID = "MYUID"
export const HOST_MEMBERS = "HOST_MEMBERS"
export const AUDIENCE_MEMBERS = "AUDIENCE_MEMBERS"

/* Check for Task */
export const IS_TASK_UPDATE = "IS_TASK_UPDATE"

export const IS_DATA_FIELD_UPDATE = "IS_DATA_FIELD_UPDATE"
export const PHYCHI_FORMS_FIELDS = "PHYCHI_FORMS_FIELDS"


// Forms Data>>>>>>>>>>>>>>>>
export const FORMS_DATA = "FORMS_DATA"