import { FACILITY_RIBBENS } from "../type.js";

const INIT_STATE = {
    get_facility_ribben: null,
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case FACILITY_RIBBENS:
            if (payload?.status) {
                return {
                    ...state,
                    get_facility_ribben: payload
                }
            }
        default:
            return state;
    }
}