import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

// Family Members
export const getMyPatient = (callback) => async (dispatch) => {
    let token = localStorage.getItem('family_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}family_members/my_patient_detail`,
    }
    const res = await apiCall(config, dispatch);
    callback(res?.data)
}

export const getMyPatientTreatmentTeamMembers = (callback) => async (dispatch) => {
    let token = localStorage.getItem('family_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}family_members/get_my_patient_treatment_members`,
    }
    const res = await apiCall(config, dispatch);
    callback(res?.data)
}

// patients
export const getMyFamilyMembers = (callback) => async (dispatch) => {
    let token = localStorage.getItem('patient_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}patients/get_my_family_members`,
    }
    const res = await apiCall(config, dispatch);
    callback(res?.data)
}

export const getMyTreatmentMembers = (callback) => async (dispatch) => {
    let token = localStorage.getItem('patient_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}patients/get_my_treatment_members`,
    }
    const res = await apiCall(config, dispatch);
    callback(res?.data)
}