import { IS_LIVE, IS_MINIMIZE, IS_CALL_MINIMIZE } from "../../type"
export const setIsLive = (data) => async (dispatch) => {
    dispatch({
        type: IS_LIVE,
        payload: data
    });
}
export const setIsMinimize = (data) => async (dispatch) => {
    dispatch({
        type: IS_MINIMIZE,
        payload: data
    });
}

export const setIsCallMinimize = (data) => async (dispatch) => {
    dispatch({
        type: IS_CALL_MINIMIZE,
        payload: data
    });
}