import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
export const allFormsData = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/get_all_forms`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};


/* Get All Reports */
export const getAllReports = (data, successCb, failureCb) => async (dispatch) => {
    const token = localStorage.getItem('patient_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}patients/get_all_reports`,
        data,
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

/* Get All Form History */
export const getAllFormHistory = (data, successCb, failureCb) => async (dispatch) => {
    const token = localStorage.getItem('patient_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}patients/get_all_histories_of_form`,
        data,
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

/* Get Single Form History */
export const getSingleFormHistory = (data, successCb, failureCb) => async (dispatch) => {
    const token = localStorage.getItem('patient_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}patients/get_single_history_form`,
        data,
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const getSingleFormRecordHistory = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/get_single_form_record_history`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};


export const allReportData = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/get_all_reports`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const allReportHistoryData = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/get_single_report`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const downloadFormReport = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}common/download_patient_form_data`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

/* Get Single Patient Dynamic Data  */
export const getPatientDynSingleForm = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/get_single_form`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const addSelfEmissionWizardMenu = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/add_self_emission_wizard_menu`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const listSelfEmissionWizardMenu = ( successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/list_self_emission_wizard_menu`,
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const updateSelfEmissionWizardMenu = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/update_self_emission_wizard_menu`,
        data
    };
    let response = await apiCall(config, dispatch); 
    if (response?.data?.status) {
        successCb(response?.data);
    } else {
        failureCb(response?.data)
    }
};

export const getDoctorsList = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}providers/get_providers_by_role`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const getSitterList = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}providers/get_providers_by_department`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const addSelfEmissionWizard = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/add_self_emission_wizard`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const updateSelfEmissionWizard = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/update_self_emission_wizard`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const listSelfEmissionWizard = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/list_self_emission_wizard`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const getSingleWizardList = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/get_self_emission_by_id`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};
export const listSelfEmissionWizardMenuByPatient = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/get_self_emission_by_patient_id`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const deleteWizardData = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/update_self_emission_wizard`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const medicationSugg = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}facilities/search_current_medication_recommendation`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const getPerticularEmissionData = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}common/get_self_emission_by_id`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};
