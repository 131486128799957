import React, { useEffect, useState } from 'react';
import { Button, Spin, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { listSingleProducts } from '../../../redux/action/patient/shop';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css'
import { UploadOutlined } from '@ant-design/icons';
import { baseUrl, fileUrl } from '../../../Constant/url';

const ViewProducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState("");
    const [loading, setLoading] = useState(false);
    const facilityData = useSelector((state) => state?.auth?.get_user);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const location = useLocation();
    const id = new URLSearchParams(location.search).get('id');
    const [fileList, setFileList] = useState([]);
    const handleInputChange = (e) => {
        setInputVal({ ...inputVal, [e.target.name]: e.target.value })
    }

    const getProducts = () => {
        setLoading(true)
        const successRes = (res) => {
            setLoading(false)
            let data = {
                medication_title: res?.data?.medication_title,
                menufacturer: res?.data?.menufacturer,
                consume_type: res?.data?.consume_type,
                medication_price: res?.data?.medication_price
            }
            setInputVal(data);
            let imgArr = [];
            let image = res?.data?.medication_link;
            image.map((m, i) => {
                let itm =
                {
                    uid: i,
                    name: 'image.png',
                    status: 'done',
                    url: fileUrl + m,
                }
                imgArr.push(itm)
            })
            setFileList(imgArr)

        }
        const failureRes = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }
        let data = {
            medication_id: id,
        }
        dispatch(listSingleProducts(data, successRes, failureRes))
    }


    useEffect(() => {
        if (facilityData?.user_type == 'super') {
            setIsSuperAdmin(true)
        } else {
            facilityData?.access?.forEach((itm) => {
                if (itm?.menu_name == 'Patients') {
                    setReadWritePermit(itm)
                }
            })
            setIsSuperAdmin(false)
        }
        getProducts();
    }, [isSuperAdmin, facilityData, readWritePermit?.value?.write]);

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className="row">
                        <div className="">
                            <div className="profile_card col-lg-6 col-xxl-5 mx-auto">
                                <h1 className='large_font pb-3'>Product Detail</h1>
                                <div className="row g-4 d-flex align-items-center">
                                    <div className="col-md-12">
                                        <div className=" extra_padding" style={{ height: "100%" }}>
                                            <div className="row gy-3">
                                                <Spin spinning={loading}>
                                                    <div className="col-lg-12">
                                                        <label className='small_font'>Product images</label>
                                                        <div className=" d-flex align-items-center">
                                                            <Upload
                                                                name='facility_media'
                                                                action={`${baseUrl}facilities/upload_media`}
                                                                fileList={fileList}
                                                                onPreview={onPreview}
                                                                listType="picture-card"
                                                                className='pt-3 pb-2 file-facility' >
                                                            </Upload>
                                                        </div>
                                                    </div>
                                                </Spin>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Product Name</label>
                                                    <input
                                                        className='input_fields'
                                                        name="medication_title"
                                                        onChange={handleInputChange}
                                                        disabled
                                                        value={inputVal?.medication_title}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Manufacturer/Marketer</label>
                                                    <input
                                                        className='input_fields'
                                                        name="menufacturer"
                                                        disabled
                                                        onChange={handleInputChange}
                                                        value={inputVal?.menufacturer}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Consume Type</label>
                                                    <input
                                                        className='input_fields'
                                                        name="consume_type"
                                                        disabled
                                                        onChange={handleInputChange}
                                                        value={inputVal?.consume_type}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Price</label>
                                                    <input
                                                        className='input_fields'
                                                        name="medication_price"
                                                        disabled
                                                        onChange={handleInputChange}
                                                        type='number'
                                                        value={inputVal?.medication_price}
                                                    />
                                                </div>
                                                <div className="w-50 mx-auto">
                                                    <Button type='ghost' onClick={() => navigate("/facility_shop")} className="common_btn mt-3">Back</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ViewProducts;
