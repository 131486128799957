import React, { Suspense, lazy } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import WelcomeScreen from '../components/WelcomeScreen';

// Protected Screens
import ProtectedRoute from './protectedRoute';
import Selfemissionwizard from '../screens/patient/selfemissionwizard/SelfEmissionWizard.js';
import SelfEmissionWizardAdd from '../screens/patient/selfemissionwizard/SelfEmissionWizardAdd.js';

// Invoice Screens
const InvoiceList = lazy(() => import('../screens/invoice/InvoiceList'));

// Before Login Screens
const Login = lazy(() => import('../screens/auth/Login'));
const ProviderSignUp = lazy(() => import('../screens/auth/ProviderSignUp'));
const ForgotPassword = lazy(() => import('../screens/auth/ForgotPassword'));
const GoogleAuth = lazy(() => import('../screens/auth/GoogleAuth'));
const VerificationScreen = lazy(() => import('../screens/auth/VerificationScreen'));
const CreateAppointment = lazy(() => import('../screens/facility/patient/CreateAppointment'));

// After Login Administrator Screens
const Dashboard = lazy(() => import('../screens/administrator/dashboard/Dashboard'));
const Facility = lazy(() => import('../screens/administrator/facility/Facility'));
const Department = lazy(() => import('../screens/administrator/department/Department'));
const AdminProfile = lazy(() => import('../screens/administrator/profile/AdminProfile'));
const ChangePassword = lazy(() => import('../components/ChangePassword'));
const Roles = lazy(() => import('../screens/administrator/department/Roles'));
const AddFacility = lazy(() => import('../screens/administrator/facility/AddFacility'));
const ManageSupervisor = lazy(() => import('../screens/administrator/department/ManageSupervisor'));

// After Login Facility Screens
const FacilityDashboard = lazy(() => import('../screens/facility/dashboard/FacilityDashboard'));
const FacilityProfile = lazy(() => import('../screens/facility/profile/FacilityProfile'));
const CallRecord = lazy(() => import('../screens/facility/SOS/CallRecord'));
const CallRecordDetail = lazy(() => import('../screens/facility/SOS/CallRecordDetail'));
const FacilityDepartment = lazy(() => import('../screens/facility/department/FacilityDepartment'));
const AssignSupervisior = lazy(() => import('../screens/facility/department/AssignSupervisior'));
const ViewProviders = lazy(() => import('../screens/facility/department/ViewProviders'));
const FacilityProvider = lazy(() => import('../screens/facility/provider/FacilityProvider'));
const AddProvider = lazy(() => import('../screens/facility/provider/AddProvider'));
const FacilityEditProvider = lazy(() => import('../screens/facility/provider/EditProvider'));
const FacilityPatient = lazy(() => import('../screens/facility/patient/FacilityPatient'));
const Hospital = lazy(() => import('../screens/facility/hospital/Hospital'));
const AddHospital = lazy(() => import('../screens/facility/hospital/AddHospital'));
const ViewHospital = lazy(() => import('../screens/facility/hospital/ViewHospital'));
const EditPatient = lazy(() => import('../screens/facility/patient/EditPatient'));
const AddPatient = lazy(() => import('../screens/facility/patient/AddPatient'));
const Banner = lazy(() => import('../screens/facility/banner/Banner'));
const AddBanner = lazy(() => import('../screens/facility/banner/AddBanner'));
const FacilitySentence = lazy(() => import('../screens/facility/sentence/FacilitySentence'));
const AddSentence = lazy(() => import('../screens/facility/sentence/AddSentence'));
const AddMeta = lazy(() => import('../screens/facility/sentence/AddMeta'));
const FacilityPresentation = lazy(() => import('../screens/facility/presentation/FacilityPresentation'));
const AddPresentation = lazy(() => import('../screens/facility/presentation/AddPresentation'));
const DynamicDataField = lazy(() => import('../screens/facility/dynamicdata/DynamicDataField'));
const DynamicForm = lazy(() => import('../screens/facility/dynamicform/DynamicForm'));
const CreateDynamicForm = lazy(() => import('../screens/facility/dynamicform/CreateDynamicForm'));
const ControlPanel = lazy(() => import('../screens/facility/controlpanel/ControlPanel'));
const FacilityTask = lazy(() => import('../screens/facility/task/FacilityTask'));
const FacilityFax = lazy(() => import('../screens/facility/fax/FacilityFax'));
const SendFax = lazy(() => import('../screens/facility/fax/SendFax'));
const FacilityGraph = lazy(() => import('../screens/facility/graph/FacilityGraph'));
const CreatePoll = lazy(() => import('../screens/facility/graph/votegraph/CreatePoll'));
const SubAdmin = lazy(() => import('../screens/facility/subadmin/SubAdmin'));
const SubAdminDetail = lazy(() => import('../screens/facility/subadmin/SubAdminDetail'));
const Zoom = lazy(() => import('../screens/facility/zoom/Zoom'));
const ContactList = lazy(() => import('../screens/facility/contact/ContactList'));
const ScheduleList = lazy(() => import('../screens/facility/schedule/ScheduleList'));
const Forms = lazy(() => import('../screens/facility/patient/forms/Forms'));
const HistoryList = lazy(() => import('../screens/facility/patient/forms/HistoryList'));
const ViewFormHistory = lazy(() => import('../screens/facility/patient/forms/ViewFormHistory'));
const Reports = lazy(() => import('../screens/facility/patient/report/Reports'));
const ReportsSingle = lazy(() => import('../screens/facility/patient/report/ReportsSingle'));
const AddReportRecord = lazy(() => import('../screens/facility/patient/report/AddReportRecord'));
const ViewReportHistory = lazy(() => import('../screens/facility/patient/report/ViewReportHistory'));
const StaffMembers = lazy(() => import('../screens/facility/staff/StaffMembers'));
const AddStaffMember = lazy(() => import('../screens/facility/staff/AddStaffMember'));
const EditStaffMember = lazy(() => import('../screens/facility/staff/EditStaffMember'));
const Booking = lazy(() => import('../screens/facility/Booking/Booking'));
const Appointments = lazy(() => import('../screens/facility/stream-appointment/Appointments'));
const LandingMenu = lazy(() => import('../screens/facility/landing-menu'));
const UpdateLandingMenu = lazy(() => import('../screens/facility/landing-menu/update'));
const ManageRibbons = lazy(() => import('../screens/facility/manageribbons/ManageRibbons'));
const ActivityLog = lazy(() => import('../screens/facility/ActivityLog/ActivityLog'));
const ShopList = lazy(() => import('../screens/facility/shop/ShopList.js'));
const AudioTask = lazy(() => import('../screens/facility/task/AudioTask.js'));
const PatientReportsUpdate = lazy(() => import('../screens/facility/patient/report/EditReportRecord.js'));
const PatientTasks = lazy(() => import('../components/PatientTasks.js'));



import SelfEmissionWizardMenu from '../screens/facility/selfemissionwizardmenu/SelfEmissionWizardMenu.js';
import SelfEmissionWizardAddMenu from '../screens/facility/selfemissionwizardmenu/SelfEmissionWizardAddMenu.js';
import SelfEmissionWizardEdit from '../screens/patient/selfemissionwizard/SelfEmissionWizardEdit.js';
import AddProducts from '../screens/facility/shop/AddProducts.js';
import UpdateProducts from '../screens/facility/shop/UpdateProducts.js';
import ViewProducts from '../screens/facility/shop/ViewProduct.js';
import OrderList from '../screens/facility/orders/OrderList.js';

// After Login Patient Screens
const PatientHome = lazy(() => import('../screens/patient/dashboard/PatientHome'));
const PatientProfile = lazy(() => import('../screens/patient/PatientProfile'));
const PatientPoll = lazy(() => import('../screens/patient/poll/PatientPoll'));
const PatientInvoice = lazy(() => import('../screens/patient/invoice/PatientInvoice'));
const PatientView = lazy(() => import('../screens/facility/patient/PatientView'));
const AppointmentList = lazy(() => import('../screens/facility/patient/AppointmentList'));
const PatientVisualization = lazy(() => import('../screens/patient/datavisualization/DataVisualization.jsx'));

// After Login Family Screens
const FamilyHome = lazy(() => import('../screens/family/dashboard/FamilyHome'));
const FamilyProfile = lazy(() => import('../screens/family/profile/FamilyProfile'));
const DataVisualization = lazy(() => import('../screens/family/datavisualization/DataVisualization'));
const FamilyPresentation = lazy(() => import('../screens/family/presentation/FamilyPresentation'));
const AddFamilyPresentation = lazy(() => import('../screens/family/presentation/AddFamilyPresentation'));
const FamilyPoll = lazy(() => import('../screens/family/poll/FamilyPoll'));
const ViewPollResult = lazy(() => import('../screens/family/poll/ViewPollResult'));

// After Login Provider Screens
const ProviderDashboard = lazy(() => import('../screens/Provider/Dashboard/ProviderDashboard'));
const ProviderProfile = lazy(() => import('../screens/Provider/profile/ProviderProfile'));
const ProviderStaffProfile = lazy(() => import('../screens/Provider/profile/ProviderStaffProfile'));
const Recording = lazy(() => import('../screens/Provider/Recordings/Recording'));
const AddSubadmin = lazy(() => import('../screens/facility/subadmin/AddSubadmin'));
const CreateDynamicField = lazy(() => import('../screens/facility/dynamicdata/CreateDynamicField'));
const UpdateDynamicField = lazy(() => import('../screens/facility/dynamicdata/UpdateDynamicField'));
const UpdateDynamicForm = lazy(() => import('../screens/facility/dynamicform/UpdateDynamicForm'));
const AssignMember = lazy(() => import('../screens/Provider/Asssignmember/AssignMember'));
const AssignTask = lazy(() => import('../screens/Provider/Assigntask/AssignTask'));
const MyTask = lazy(() => import('../screens/Provider/Mytask/MyTask'));
const Schedule = lazy(() => import('../screens/Provider/Schedule/Schedule'));
const ProviderControlPanel = lazy(() => import('../screens/Provider/Controlpanel/ProviderControlPanel'));
const ProviderPresentation = lazy(() => import('../screens/Provider/Presentatiion/ProviderPresentation'));
const AddProviderPresentation = lazy(() => import('../screens/Provider/Presentatiion/AddProviderPresentation'));
const Streaming = lazy(() => import('../screens/Provider/Streaming/Streaming'));
const ProviderPoll = lazy(() => import('../screens/Provider/Poll/ProviderPoll'));
const Bookings = lazy(() => import('../screens/Provider/Bookings/Bookings'));
const Invoice = lazy(() => import('../screens/invoice/Invoice.js'));
const PatientProfle = lazy(() => import('../screens/facility/patient/ProfileView'));
const ProviderRole = lazy(() => import('../screens/facility/role/ProvderRole'));
const NoPermit = lazy(() => import('../screens/facility/NoPermit'));
const AudioTaskProvider = lazy(() => import('../screens/Provider/Mytask/AudioTask.js'));

// Meeting
const Meeting = lazy(() => import('../screens/facility/meeting'));

/*After Login Hospital Screens  */
const HospitalDashboard = lazy(() => import('../screens/Hospital/Dashboard/HospitalDashboard'));
const HospitalCP = lazy(() => import('../screens/Hospital/ControlPanel/HospitalCP'));
const HospitalBooking = lazy(() => import('../screens/Hospital/Booking/HospitalBooking'));
const HospitalPatient = lazy(() => import('../screens/Hospital/Patient/HospitalPatient'));
const HospitalInvoice = lazy(() => import('../screens/Hospital/Invoice/HospitalInvoice'));
const HospitalProvider = lazy(() => import('../screens/Hospital/Provider/HospitalProvider'));
const HospitalFax = lazy(() => import('../screens/Hospital/Fax/HospitalFax'));
const HospitalProfile = lazy(() => import('../screens/Hospital/profile/HospitalProfile'));
const HospitalGraph = lazy(() => import('../screens/Hospital/graph/HospitalGraph'));

const routes = () => {
  return (
    <>
      <Suspense fallback={<WelcomeScreen />}>
        <HashRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route exact path='/admin_dashboard' element={<Dashboard />} />
              <Route exact path='/facility' element={<Facility />} />
              <Route exact path='/department' element={<Department />} />
              <Route exact path='/admin_profile' element={<AdminProfile />} />
              <Route exact path='/changepassword' element={<ChangePassword />} />
              <Route exact path='/addroles' element={<Roles />} />
              <Route exact path='/addfacility' element={<AddFacility />} />

              {/* Facility Routes start */}
              <Route path='/NoPermit' element={<NoPermit />} />
              <Route path='/facility_sos_call_record' element={<CallRecord />} />
              <Route path='/facility_sos_call_record_detail' element={<CallRecordDetail />} />
              <Route path='/facility-profile' element={<FacilityProfile />} />
              <Route exact path='/dashboard' element={<FacilityDashboard />} />
              <Route exact path='/facility_departments' element={<FacilityDepartment />} />
              <Route exact path='/assign_supervisior' element={<AssignSupervisior />} />
              <Route exact path='/view_providers' element={<ViewProviders />} />
              <Route exact path='/facility_providers' element={<FacilityProvider />} />
              <Route exact path='/add-provider' element={<AddProvider />} />
              <Route exact path='/facility_edit_providers' element={<FacilityEditProvider />} />
              <Route exact path='/patients' element={<FacilityPatient />} />
              <Route exact path='/hospitals' element={<Hospital />} />
              <Route exact path='/add-hospital' element={<AddHospital />} />
              <Route exact path='/view-hospital' element={<ViewHospital />} />
              <Route exact path='/facility_patient_appointment' element={<CreateAppointment />} />
              <Route exact path='/edit_patient' element={<EditPatient />} />
              <Route exact path='/facility_patient_view' element={<PatientView />} />
              <Route exact path='/facility_patient_profile_view' element={<PatientProfle />} />
              <Route exact path='/add_patient' element={<AddPatient />} />
              <Route exact path='/facility_banners' element={<Banner />} />
              <Route exact path='/add_banner' element={<AddBanner />} />
              <Route exact path='/facility_sentencelist' element={<FacilitySentence />} />
              <Route exact path='/add_facility_sentence' element={<AddSentence />} />
              <Route exact path='/add_facility_meta' element={<AddMeta />} />
              <Route exact path='/facility_presentation' element={<FacilityPresentation />} />
              <Route exact path='/add_facility_presentation' element={<AddPresentation />} />
              <Route exact path='/facility_dynamicdata' element={<DynamicDataField />} />
              <Route exact path='/create_dynamic_field' element={<CreateDynamicField />} />
              <Route exact path='/update_dynamic_field/:id' element={<UpdateDynamicField />} />
              <Route exact path='/update_dynamic_form/:id' element={<UpdateDynamicForm />} />
              <Route exact path='/facility_dynamicform' element={<DynamicForm />} />
              <Route exact path='/create_dynamic_form' element={<CreateDynamicForm />} />
              <Route exact path='/facility_controlpanel' element={<ControlPanel />} />
              <Route exact path='/facility_fax' element={<FacilityFax />} />
              <Route exact path='/staff_members' element={<StaffMembers />} />
              <Route exact path='/add_staff_member' element={<AddStaffMember />} />
              <Route exact path='/edit_staff_member' element={<EditStaffMember />} />
              <Route exact path='/facility_send_fax' element={<SendFax />} />
              <Route exact path='/facility_graph' element={<FacilityGraph />} />
              <Route exact path='/create-poll' element={<CreatePoll />} />
              <Route exact path='/facility_subadmin' element={<SubAdmin />} />
              <Route exact path='/facility_subadmin_detail' element={<SubAdminDetail />} />
              <Route exact path='/add_subadmin' element={<AddSubadmin />} />
              <Route exact path='/facility_24*7' element={<Zoom />} />
              <Route exact path='/meeting' element={<Meeting />} />
              <Route exact path='/meeting/:link' element={<Meeting />} />
              <Route exact path='/contact_list' element={<ContactList />} />
              <Route exact path='/schedule_list' element={<ScheduleList />} />
              <Route exact path='/patient_forms/:id' element={<Forms />} />
              <Route exact path='/add_report_record/:id' element={<AddReportRecord />} />
              <Route exact path='/history_list/:id/:id' element={<HistoryList />} />
              <Route exact path='/view_form_history/:id/' element={<ViewFormHistory />} />
              <Route path='/invoice' element={<Invoice />} />
              <Route path='/invoices_list' element={<InvoiceList />} />
              <Route path='/facility_provider_mngsuprvsr' element={<ManageSupervisor />} />
              <Route path='/facility_provider_role' element={<ProviderRole />} />
              <Route path='/patient_reports/:id' element={<Reports />} />
              <Route path='/patient_reports_single/:id/:id' element={<ReportsSingle />} />
              <Route path='/view_report_history/:id/:id' element={<ViewReportHistory />} />
              <Route path='/appointment_list' element={<AppointmentList />} />
              <Route path='/facility_booking' element={<Booking />} />
              <Route path='/streaming-appointment' element={<Appointments />} />
              <Route path='/landing-menu' element={<LandingMenu />} />
              <Route path='/update-landing-menu/:id' element={<UpdateLandingMenu />} />
              <Route path='/manage-ribbons' element={<ManageRibbons />} />
              <Route path='/activityLogs' element={<ActivityLog />} />
              <Route path='/self_emission_wizard_menu_facility' element={<SelfEmissionWizardMenu />} />
              <Route path='/self_emission_wizard_menu_add' element={<SelfEmissionWizardAddMenu />} />
              <Route path='/facility_shop' element={<ShopList />} />
              <Route path='/add_products' element={<AddProducts />} />
              <Route path='/update_product' element={<UpdateProducts />} />
              <Route path='/view_product' element={<ViewProducts />} />
              <Route path='/order' element={<OrderList />} />
              <Route path='/audio_task' element={<AudioTask />} />
              <Route path='/patient_reports_update/:id/:id' element={<PatientReportsUpdate />} />
              <Route path='/patient_tasks/:id' element={<PatientTasks />} />
              
              
              {/* Provider Routes start*/}
              <Route path='/provider_dashboard' element={<ProviderDashboard />} />
              <Route path='/provider-profile' element={<ProviderProfile />} />
              <Route path='/provider-starff-profile' element={<ProviderStaffProfile />} />
              <Route path='/provider_recordings' element={<Recording />} />
              <Route path='/provider_assign_member' element={<AssignMember />} />
              <Route path='/provider_assign_task' element={<AssignTask />} />
              <Route path='/provider_task' element={<MyTask />} />
              <Route path='/provider_schedule' element={<Schedule />} />
              <Route path='/provider_controlpanel' element={<ProviderControlPanel />} />
              <Route path='/provider_presentation' element={<ProviderPresentation />} />
              <Route path='/add_provider_presentation' element={<AddProviderPresentation />} />
              <Route path='/provider_streaming' element={<Streaming />} />
              <Route path='/provider_poll' element={<ProviderPoll />} />
              <Route path='/provider_bookings' element={<Bookings />} />
              <Route path='/task_list_provider' element={<AudioTaskProvider />} />

              {/* Patient Routes start*/}
              <Route exact path='/patient_home' element={<PatientHome />} />
              <Route exact path='/patient_poll' element={<PatientPoll />} />
              <Route exact path='/patient_invoice' element={<PatientInvoice />} />
              <Route exact path='/patient_data-visualization' element={<PatientVisualization />} />
              <Route exact path='/self_emission_wizard' element={<Selfemissionwizard />} />
              <Route exact path='/self_emission_wizard_add' element={<SelfEmissionWizardAdd />} />
              <Route exact path='/self_emission_wizard_edit' element={<SelfEmissionWizardEdit />} />

            
              {/* family Routes start*/}
              <Route exact path='/family_home' element={<FamilyHome />} />
              <Route exact path='/patient-profile' element={<PatientProfile />} />
              <Route exact path='/family-profile' element={<FamilyProfile />} />
              <Route exact path='/data_visualization' element={<DataVisualization />} />
              <Route exact path='/family_presentation' element={<FamilyPresentation />} />
              <Route exact path='/add_family_presentation' element={<AddFamilyPresentation />} />
              <Route exact path='/family_poll' element={<FamilyPoll />} />
              <Route exact path='/view-poll-result/:id' element={<ViewPollResult />} />

              {/* Hospital */}
              <Route exact path='/hospital_dashboard' element={<HospitalDashboard />} />
              <Route exact path='/hospital_control-panel' element={<HospitalCP />} />
              <Route exact path='/hospital_bookings' element={<HospitalBooking />} />
              <Route exact path='/hospital_patients' element={<HospitalPatient />} />
              <Route exact path='/hospital_invoices' element={<HospitalInvoice />} />
              <Route exact path='/hospital_providers' element={<HospitalProvider />} />
              <Route exact path='/hospital_fax' element={<HospitalFax />} />
              <Route exact path='/hospital_profile' element={<HospitalProfile />} />
              <Route exact path='/hospital_graphs' element={<HospitalGraph />} />

            </Route>
            <Route path='/' element={<Login />} />
            <Route path='/provider-signup' element={<ProviderSignUp />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/google-authentication' element={<GoogleAuth />} />
            <Route exact path='/verify-authentication' element={<VerificationScreen />} />
          </Routes>
        </HashRouter>
      </Suspense>
    </>
  )
}

export default routes;
