import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "../src/store/store.js";
import Routers from './routes/routes.js';

const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routers />
        </PersistGate>
      </Provider>
    </>
  )
}
export default App