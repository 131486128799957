import { IS_LIVE, IS_MINIMIZE, IS_CALL_MINIMIZE, IS_CHAT_MODAL_OPEN, CALANDER_EVENT, IS_DATA_FIELD_UPDATE } from "../type.js";

const INIT_STATE = {
    is_live: false,
    is_minimize: false,
    is_call_minimize: false,
    is_contract_expired: false,
    is_data_field_update:false
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case IS_LIVE:
            return {
                ...state,
                is_live: payload
            }
        case IS_MINIMIZE:
            return {
                ...state,
                is_minimize: payload
            }
        case IS_CALL_MINIMIZE:
            return {
                ...state,
                is_call_minimize: payload
            }
        case IS_CHAT_MODAL_OPEN:
            return {
                ...state,
                is_chat_open: payload
            }
        case CALANDER_EVENT:
            return {
                ...state,
                calander_event: payload
            }
        case IS_DATA_FIELD_UPDATE:
            return {
                ...state,
                is_data_field_update: payload
            }
        default:
            return state;
    }
}