import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ProtectedRoute = () => {
  const admin_token = localStorage.getItem('admin_token');
  const facility_token = localStorage.getItem('facility_token');
  const provider_token = localStorage.getItem('provider_token');
  const patient_token = localStorage.getItem('patient_token');
  const family_token = localStorage.getItem('family_token');
  const super_token = localStorage.getItem("super_token");
  const hospital_token = localStorage.getItem('hospital_token');

  useEffect(() => {
    const hash = window.location.hash;

    // Check if the hash contains "meeting"
    if (hash.includes("meeting")) {      
      // Save the hash to localStorage after a slight delay
      localStorage.setItem("unauthorizedMeetingUrl", hash.substring(1)); // Removing the "#" at the beginning
    }
  }, []);

  function navigateToDashboard() {
    setTimeout(() => {
      document.location.href="/"
    }, 500);
    
    // return (
    //   <Navigate to="/" replace={true} />
    // );  
  }

  return (
    <>
      {admin_token || facility_token || provider_token || patient_token || family_token || super_token || hospital_token ?
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
        :
        navigateToDashboard()
      }
    </>
  );
};

export default ProtectedRoute;
