import React, { useEffect, useState } from 'react';
import taskVector from '../../../assets/facilityimg/task.png';
import { useDispatch } from 'react-redux';
import { listSelfEmissionWizardMenu, updateSelfEmissionWizardMenu } from '../../../redux/action/patient/form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Popconfirm, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

const SelfEmissionWizardMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menulist, setMenulist] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const [indexVal, setIndexVal] = useState(0);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const successRes = (res) => {
            setMenulist(res?.data);
        };
        const failureRes = (res) => {
            setMenulist([]);
        };
        dispatch(listSelfEmissionWizardMenu(successRes, failureRes));
    };

    const updateStatus = (_id, val) => {
        const successRes = (res) => {
            toast.success(res?.message);
            getData();
        };
        const failureRes = (res) => {
            toast.error(res?.message);
        };
        let data = {
            _id,
            status: val,
        };
        dispatch(updateSelfEmissionWizardMenu(data, successRes, failureRes));
    };

    const menuModal = (i) => {
        setIndexVal(i);
        setInputVal(menulist[i]?.text);
        setEditModal(true);
    };

    const handleUpdate = () => {
        const success = (res) => {
            toast.success(res?.message);
            setEditModal(false);
            getData();
            setInputVal('');
        };
        const failure = (res) => {
            toast.error(res?.message);
        };
        let data = {
            _id: menulist[indexVal]?._id,
            text: inputVal,
            status: 1
        };
        dispatch(updateSelfEmissionWizardMenu(data, success, failure));
    };

    const handleModalInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 100) {
            setInputVal(value);
        } else {
            toast.error("Input cannot exceed 100 characters.");
        }
    };

    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className={"card p-4"}>
                        <div className="row gy-4 d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-xl-3">
                                <div className="card_div" style={{ background: "#edf5fb" }}>
                                    <div className="content">
                                        <b style={{ fontFamily: "Roboto, sans-serif", color: "#104861" }} className="medium_font">
                                            Self Emission Wizard menu
                                        </b>
                                        <br />
                                        <b style={{ fontFamily: "Roboto, sans-serif", color: "#104861" }} className="medium_font">{menulist?.length}</b>
                                    </div>
                                    <img src={taskVector} alt="" width="40%" />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-xl-3 ">
                                <button onClick={() => navigate('/self_emission_wizard_menu_add')} className="common_btn medium_font" style={{ borderRadius: "50px", float: "right" }}>
                                    Add menu
                                </button>
                            </div>
                        </div>

                        {/* Table  */}
                        <div className='w-100' style={{ overflow: "auto" }}>
                            <div className="table_width">
                                <table className='text-center table-striped mt-5 w-100'>
                                    <thead className='table_div'>
                                        <tr>
                                            <th className='text-center px-2'>#</th>
                                            <th>Menu Name</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {menulist?.length > 0 ? menulist.map((no, index) => (
                                            <tr key={index}>
                                                <td> {index + 1}</td>
                                                <td> {no.text}</td>
                                                <td> {moment.unix(no?.created_at).format("MM-DD-YYYY MM:SS A")}</td>
                                                <td>

                                                    <Popconfirm
                                                        title="Delete"
                                                        description="Are you sure want to delete this data?"
                                                        onConfirm={() => updateStatus(no?._id, "2")}
                                                        icon={
                                                            <QuestionCircleOutlined
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            />
                                                        }
                                                    >
                                                        <span variant="info" size="sm" className='me-2 my-1 pointer' >Delete</span>
                                                        |&nbsp;&nbsp;
                                                    </Popconfirm>
                                                    <span variant="info" size="sm" className='me-2 my-1 pointer' onClick={() => menuModal(index)}>Edit</span>
                                                </td>
                                            </tr>
                                        )) : <div style={{ textAlign: "center", width: "100%", marginTop: "20px" }}>No Data Found</div>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* Edit Sentence Modal */}
                        <Modal open={editModal} footer={null} closable={false}>
                            <div className="row">
                                <div className="">
                                    <div className="">
                                        <h1 className='large_font pb-3'>Edit Menu</h1>
                                        <div className="row gy-4">
                                            <div className="col-lg-12">
                                                <label className='small_font'>Menu</label>
                                                <input
                                                    value={inputVal}
                                                    onChange={handleModalInputChange}
                                                    type="text" className="input_fields"
                                                />
                                            </div>                                           
                                            <div className="d-flex mt-5">
                                                <button onClick={handleUpdate} className="common_btn me-3">Save</button>
                                                <button onClick={() => setEditModal(false)} className="common_btn2">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>
            </div>

        </>
    )
}

export default SelfEmissionWizardMenu;
