import { USER_EXIST, IS_TASK_UPDATE } from "../type";
import { baseUrl } from "../../Constant/url";
import { apiCall } from "../../api";

export const checkUserExist = (data) => async (dispatch) => {
    dispatch({
        type: USER_EXIST,
        payload: data,
    });

}
export const taskAPiHitNeeded = (data) => async (dispatch) => {
    dispatch({
        type: IS_TASK_UPDATE,
        payload: data,
    });
}

export const manageHistoryAction = (data, callback) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/manage_history`,
        data,
    }
    const response = await apiCall(config, dispatch);
    callback(response?.data)
};

export const getMedicationPlans = (data, successCb, failureCb, endpoint) => async (dispatch) => {
    const token = endpoint==='facilities'?localStorage.getItem('facility_token'):localStorage.getItem('provider_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}${endpoint}/get_medication_plan`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const addMedicalPlan = (data, successCb, failureCb ,endpoint) => async (dispatch) => {
    const token = endpoint==='facilities'?localStorage.getItem('facility_token'):localStorage.getItem('provider_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}${endpoint}/add_medication_plan`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};

export const updateMedicalPlan = (data, successCb, failureCb,endpoint) => async (dispatch) => {
    const token = endpoint==='facilities'?localStorage.getItem('facility_token'):localStorage.getItem('provider_token')
    let config = {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        url: `${baseUrl}${endpoint}/update_medication_plan`,
        data
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        failureCb(error)
    }
};
