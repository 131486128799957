import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fileUrl, zoomBaseUrl } from '../Constant/url';
import { sendNotification, checkStatusCalling } from '../redux/action/auth/notification';
import { setIsCallMinimize } from '../redux/action/header/header';
import { onMessageListener } from '../firebase'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { CALLING_NOTIFICATION } from "../../src/redux/type";

const ChatCalling = () => {
    const dispatch = useDispatch()
    const [connectigCall, setConnectigCall] = useState(false);
    const [sessionCall, setSessionCall] = useState(false);
    const [hostType, setHostType] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [callerInfo, setCallerInfo] = useState('');
    const [callName, setCallName] = useState('');
    const [otherUserId, setOtherUserId] = useState('');
    const [otherUserType, setOtherUserType] = useState('');
    const userData = useSelector((state) => state?.auth?.get_user);
    const notificationData = useSelector((state) => state?.notification?.notofication_array);
    const isCallMinimize = useSelector((state) => state.header.is_call_minimize)
    const isLive = useSelector((state) => state.header.is_live)
    let my_user_type = localStorage.getItem("login_type");

    if (userData) {
        var user_data = userData;
        var username = my_user_type == 'Facility' ? user_data?.name : my_user_type == 'hospital' ? user_data?.name : my_user_type == 'Patient' || my_user_type == 'Family' ? user_data?.user_name != 0 ? user_data?.user_name : user_data?.first_name + " " + user_data?.last_name : user_data?.first_name + " " + user_data?.last_name;
    }

    const sendNotificationForCalling = () => {
        const successRes = (res) => {
            setConnectigCall(true);
            setHostType(1)
            setSessionCall(user_data?._id)
            setCallName(username)
            Swal.fire({
                icon: 'success',
                title: `Notifcation sent successfully!`,
                showConfirmButton: false,
                timer: 1500
            });
        }
        const failureRes = (res) => { console.log(res, "fail") }
        let data = {
            _id: notificationData?.Other_user_id,
            user_type: notificationData?.other_user_type,
            payload: {
                title: username,
                body: "Incoming call",
                noti_type: 'calling',
                sessionName: user_data?._id,
                detail: {
                    _id: user_data?._id,
                    name: username,
                    profile_pic: my_user_type == 'Facility' ? user_data?.image : user_data?.profile_pic,
                    user_type: my_user_type
                }
            }
        }
        dispatch(sendNotification(data, successRes, failureRes))
    }

    const checkStatusForCalling = () => {
        const successRes = (res) => {
            if (res?.data?.data?.chat_status === 'online') {
                sendNotificationForCalling();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: `${notificationData?.other_profile_name} isn't available for call`,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        }
        const failureRes = (res) => { console.log(res, "fail") }
        let data = {
            type: notificationData?.other_user_type,
            _id: notificationData?.Other_user_id
        }
        dispatch(checkStatusCalling(data, successRes, failureRes))
    }

    useEffect(() => {
        if (notificationData?.calling) {
            if (isLive) {
                toast.error("Sorry!! You have already connected a streaming. Please end the existing streaming to join a new streaming!")
                return;
            }
            checkStatusForCalling();
        }
    }, [notificationData]);

    onMessageListener((payload) => {
        if (payload?.data?.noti_type === "calling") {
            let callerDetail = JSON.parse(payload?.data?.detail);
            setOtherUserId(callerDetail?._id)
            setOtherUserType(callerDetail?.user_type)
            openIncomingCallModal(payload);
        }
        if (payload?.data?.noti_type === "declined") {
            Swal.fire({
                icon: 'error',
                title: `Your call has been declined!`,
                showConfirmButton: false,
                timer: 1500
            });
        }
    })

    //  handle incoming call functionality     
    const handleAcceptCall = () => {
        setHostType(0);
        setCallName(username)
        setConnectigCall(true);
        closeModal();
    };

    const handleDeclineCall = () => {
        closeModal();
        const successRes = (res) => { }
        const failureRes = (res) => { console.log(res, "fail") }
        let data = {
            _id: otherUserId,
            user_type: otherUserType,
            payload: {
                noti_type: 'declined',
            }
        }
        dispatch(sendNotification(data, successRes, failureRes))
    };

    const openIncomingCallModal = (payload) => {
        let userinfo = JSON.parse(payload?.data?.detail);
        setModalVisible(true);
        setSessionCall(payload?.data?.sessionName)
        setCallerInfo(userinfo);
        setCallName(userinfo?.name)
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const modalContainerStyle = {
        backgroundImage: "url('" + fileUrl + callerInfo?.profile_pic + "')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backdropFilter: 'blur(50px)',
        borderRadius: '8px',
        height: '40vh',
        marginBottom: "10rem",
        color: "red",
        width: "60%"
    };

    function handleMessage(event) {
        if (event.origin === zoomBaseUrl) {
            // Perform actions based on the message received from the iframe
            if (event.data === 'leaved') {
                setConnectigCall(false);
                let data = {}
                dispatch({
                    type: CALLING_NOTIFICATION,
                    payload: data,
                });
            }
        }
    }
    // Add event listener to listen for messages from the iframe
    window.addEventListener("message", handleMessage);

    return (
        <>
            {callerInfo != "" ?
                <Modal
                    visible={modalVisible}
                    footer={null}
                    onCancel={closeModal}
                    maskClosable={false}  // Disable closing on outside click
                    wrapClassName="incoming-call-modal"
                    bodyStyle={modalContainerStyle}
                    closable={false}
                >
                    <div className="incoming-call-container">
                        <h2 className="incoming-call-text">Incoming Call</h2>
                        <p className="caller-text">Caller: {callerInfo?.name}</p>
                        <div className="button-group">
                            <button className="accept-button" onClick={handleAcceptCall}>
                                Accept
                            </button>
                            <button className="decline-button" onClick={handleDeclineCall}>
                                Decline
                            </button>
                        </div>
                    </div>
                </Modal>
                : ""
            }
            {connectigCall ?
                <div className='row' style={{ display: connectigCall ? "block" : "none" }}>
                    <div className='col-10 offset-1' style={{ ...myStyles.callHeader, background: isCallMinimize ? "#ccc" : 'transparent', height: isCallMinimize ? 61 : "auto" }}>
                        <>
                            <div className='' style={{ display: isCallMinimize ? "block" : "none" }}>
                                <div className='row'>
                                    <div className='col-3 text-left'>In Call</div>
                                    <div className='col-3 offset-6 text-right'>
                                        <button
                                            style={{ marginRight: 20 }}
                                            onClick={() => dispatch(setIsCallMinimize(!isCallMinimize))}
                                            className='common_btn px-4'>
                                            + Maximize
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='' style={{ display: isCallMinimize ? "none" : "block" }}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-3 text-left'>In Call</div>
                                            <div className='col-3 offset-6 text-right'>
                                                <button
                                                    onClick={() => dispatch(setIsCallMinimize(!isCallMinimize))}
                                                    className='common_btn px-4'>
                                                    - Minimize
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 common_content" style={{ height: "auto", textAlign:"center" }}>
                                        <div className="container-fluid common_container">
                                            <iframe credentialless id="myIframe" src={zoomBaseUrl + "?topic=" + sessionCall + "&host=" + hostType + "&id=call&name=" + callName} style={{ width: "90%", height: "80vh", zIndex: 9999999 }} allow="camera; microphone; display-capture" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
                : null
            }
        </>
    )
}
const myStyles = {
    callHeader: {
        borderRadius: "5px",
        marginTop: "28px",
        padding: "5px 10px",
        lineHeight: "37px",
        boxShadow: "2px 2px 5px #ccc"
    }
}
export default ChatCalling;
