import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { DeviceUUID } from 'device-uuid';
import { collection, addDoc, updateDoc, doc, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
import moment from 'moment';
import { forwardRef, useImperativeHandle } from 'react';
import { fileUrl, zoomBaseUrl } from '../Constant/url';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLive, setIsMinimize } from "../redux/action/header/header"
import { toast } from 'react-toastify';
import { IS_LIVE } from "../../src/redux/type";

const GoLive = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({ handleTopic }))
    const [topicName, setTopicName] = useState('');
    const [facilityName, setFacilityName] = useState('');
    const [facilityImage, setFacilityImage] = useState('');
    const [attendee, setAttendee] = useState('N/A');
    const [docId, setDocId] = useState('');
    const dispatch = useDispatch()
    var leave_id = new DeviceUUID().get();
    const isLive = useSelector((state) => state.header.is_live)
    const isMinimize = useSelector((state) => state.header.is_minimize)
    const { meetingToken } = useSelector((state) => state?.meeting);
    const notificationData = useSelector((state) => state?.notification?.notofication_array);

    // // go live methods
    const handleTopic = async () => {
        if (isLive) {
            toast.error("Sorry!! You have already connected a streaming. Please end the existing streaming to join a new streaming.")
            return;
        }
        else if (meetingToken != null) {
            toast.error("Sorry !!! You have already connected a meeting, Please end the existing meeting to Join a live streaming")
            return;
        }
        else if (notificationData?.calling) {
            toast.error("Sorry!! You have already connected a call. Please end the existing call to join a new streaming!")
            return;
        }
        else {
            const { value: topic } = await Swal.fire({
                title: 'Enter your topic',
                input: 'text',
                inputPlaceholder: 'Enter your topic here...', // Placeholder text for the input field
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to enter a topic!';
                    }
                    else if (value.length > 50) {
                        return 'Please enter shorter topic name!';
                    }
                },
            });
            if (topic) {
                setTopicName(topic)
                handleCreateMeeting(topic);
            }
        }
    };

    const handleCreateMeeting = async (topic) => {
        try {
            let facilityUser = localStorage.getItem("facility_user");
            let facility = JSON.parse(facilityUser);
            const docRef = await addDoc(collection(db, "streaming"), {
                topic,
                created_at: moment().unix(),
                status: 1,
                is_busy: 0,
                receptionist_name: facility?.other?.name,
                receptionist_pic: fileUrl + facility?.other?.image,
                facility_id: facility?.other?._id,
                host_leave_id: leave_id,
            });
            updateDoc(doc(db, "streaming", docRef.id), {
                docid: docRef.id,
            }).then(() => {
                setFacilityName(facility?.other?.name)
                setFacilityImage(fileUrl + facility?.other?.image)
                setDocId(docRef.id);
                dispatch(setIsLive(true));
                dispatch(setIsMinimize(false));
                setAttendee("N/A")
                goLiveView(docRef.id);
            }).catch((error) => {
                console.log("Error updating document: ", error);
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const goLiveView = (docid) => {
        const iframe = document.getElementById('myIframe');
        window.addEventListener('message', (event) => {
            if (event.origin === zoomBaseUrl) {
                console.log('Received message from iframe:', event.data);  
                let data = {}
                dispatch({
                    type: IS_LIVE,
                    payload: data
                });             
            }
        });
        handleLiveSession(docid);
    }

    const handleLiveSession = async (docid) => {
        const dataCol = collection(db, "streaming");
        const liveSessionQuery = query(dataCol,
            where("docid", "==", docid),
            where("host_leave_id", "==", leave_id),
            where("status", "==", 0)
        );
        const unsubscribe = onSnapshot(liveSessionQuery, (snapshot) => {
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    dispatch(setIsLive(false));
                    dispatch(setIsMinimize(false));
                    setAttendee("N/A");
                    setFacilityName('');
                });
            } else {
                console.log("No document found matching the conditions.");
            }
        });
        return unsubscribe;
    }

    const getDocInfo = async (docId) => {
        const dataCol = collection(db, "streaming");
        const liveSessionQuery = query(dataCol,
            where("docid", "==", docId)
        );
        const docUnsubscribe = onSnapshot(liveSessionQuery, (snapshot) => {
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    let liveData = doc.data()
                    setAttendee(liveData?.attendee_name)
                });
            } else {
                console.log("No document found matching the conditions.");
            }
        });
        return docUnsubscribe;
    }

    useEffect(() => {
        if (docId != "") {
            getDocInfo(docId)
        }
    }, [isMinimize])

    return (
        <>
            <div className='row' style={{ display: isLive ? "block" : "none" }}>
                <div className='col-10 offset-1' style={{ ...myStyles.callHeader, background: isMinimize ? "#ccc" : 'transparent', height: isMinimize ? 50 : "auto" }}>
                    <>
                        <div className='' style={{ display: isMinimize ? "block" : "none" }}>
                            <div className='row'>
                                <div className='col-3 text-left'>On Going Live</div>
                                <div className='col-6 text-center'><b>Host - </b>{facilityName} || <b>Attendee - </b>{attendee}</div>
                                <div className='col-3 text-right'>
                                    <button
                                        style={{ marginRight: 20 }}
                                        onClick={() => dispatch(setIsMinimize(!isMinimize))}
                                        className='common_btn px-4'>
                                        + Maximize
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='' style={{ display: isMinimize ? "none" : "block" }}>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-3 text-left'>On Going Live</div>
                                        <div className='col-3 offset-6 text-right'>
                                            <button
                                                onClick={() => dispatch(setIsMinimize(!isMinimize))}
                                                className='common_btn px-4'>
                                                - Minimize
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 common_content" style={{ height: "auto" }}>
                                    <div className="container-fluid common_container">
                                        {isLive ?
                                            <iframe credentialless id="myIframe" src={zoomBaseUrl + `?topic=${topicName}&id=${docId}&host=1&name=${facilityName + "*" + facilityImage}`} style={{ width: "100%", height: "100vh" }} allow="camera; microphone; display-capture" allowfullscreen></iframe>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
})

const myStyles = {
    callHeader: {
        borderRadius: "5px",
        marginTop: "28px",
        padding: "5px 10px",
        lineHeight: "37px",
        boxShadow: "2px 2px 5px #ccc"
    }
}
export default GoLive