import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addSelfEmissionWizardMenu } from '../../../redux/action/patient/form';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css'

const SelfEmissionWizardAddMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState("");
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state?.auth?.get_user);
   
    const facilityData = useSelector((state) => state?.auth?.get_user);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
 
    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 100) {
            setInputVal(value);
        } else {
            toast.error("Input cannot exceed 100 characters.");
        }
    }

    const handleCreate = () => {
        if (inputVal.trim() === "") {
            toast.error("Please enter menu name!");
            return;
        }
        setLoading(true)
        const successRes = (res) => {
            setLoading(false)
            toast.success(res?.message)
            navigate('/self_emission_wizard_menu_facility')
        }
        const failureRes = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }
        let data = {
            text: inputVal,
            patient_id: userData?._id,
        }
        dispatch(addSelfEmissionWizardMenu(data, successRes, failureRes))
    }

    useEffect(() => {
        if (facilityData?.user_type == 'super') {
            setIsSuperAdmin(true)
        } else {
            facilityData?.access?.forEach((itm) => {
                if (itm?.menu_name == 'Patients') {
                    setReadWritePermit(itm)
                }
            })
            setIsSuperAdmin(false)
        }
    }, [isSuperAdmin, facilityData, readWritePermit?.value?.write]);

    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className="row">
                        <div className="">
                            <div className="profile_card col-lg-6 col-xxl-5 mx-auto">
                                <h1 className='large_font pb-3'>Add Self Emission Wizard Menu</h1>
                                <div className="row g-4 d-flex align-items-center">
                                    <div className="col-md-12">
                                        <div className="extra_padding" style={{ height: "100%" }}>
                                            <div className="row gy-3">
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Menu</label>
                                                    <input
                                                        className='input_fields'
                                                        name="menu"
                                                        onChange={handleInputChange}
                                                        value={inputVal}
                                                    />
                                                </div>
                                                <div className="w-50 mx-auto">
                                                    <Button type='ghost' onClick={handleCreate} className="common_btn mt-3">Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelfEmissionWizardAddMenu;
