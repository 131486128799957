import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
import { HEADER_MENU } from "../../type";

export const sendNotification = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/notify_user`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const checkStatusCalling = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/get_chat_status`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const uploadMedia = (data, type, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'mutlipart/form-data' },
        url: `${baseUrl + type}/upload_media`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

export const uploadMediaStreaming = (data, type, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'mutlipart/form-data' },
        url: `${baseUrl}common/upload_media`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        successRes(res)
    } catch (error) {
        failureRes(error)
    }
}

// export const getMenu = (data) => async (dispatch) => {
//     let config = {
//         method: "post",
//         headers: { 'Content-Type': 'application/json' },
//         url: `${baseUrl}common/get_menus`,
//         data
//     }
//     const response = await apiCall(config, dispatch);
//     console.log("response?.data",response?.data)
//     try {
//         if (response?.data?.status) {
//             dispatch({
//                 trpe: HEADER_MENU,
//                 payload: response?.data,
//             })
//         } else {
//             console.log("ERROR WHILE FETCHING HEADER MENU", response)
//         }
//     } catch (error) {
//         console.log("ERROR WHILE FETCHING HEADER MENU", error)
//     }
// }