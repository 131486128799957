import { GET_USER, FACILITY_HOSPITAL_DETAILS, PROVIDER_DEPARTMENT_DETAILS, PROVIDER_ROLES_DETAILS, HEADER_MENU } from "../type.js";

const INIT_STATE = {
    get_user: null,
    get_menu: null,
    facility_detail: null,
    provider_detail: null,
    provider_roles: null,
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER:
            if (payload?.status) {
                return {
                    ...state,
                    get_user: payload
                }
            }
            break;
        case HEADER_MENU:
            if (payload?.status) {
                return {
                    ...state,
                    get_menu: payload
                }
            }
            break;
        case FACILITY_HOSPITAL_DETAILS:
            if (payload?.status) {
                return {
                    ...state,
                    facility_detail: payload
                }
            }
        case PROVIDER_DEPARTMENT_DETAILS:
            if (payload?.status) {
                return {
                    ...state,
                    provider_detail: payload
                }
            }
        case PROVIDER_ROLES_DETAILS:
            if (payload?.status) {
                return {
                    ...state,
                    provider_roles: payload
                }
            }
        default:
            return state;
    }
}
