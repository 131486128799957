import React from 'react';
import Logo from '../assets/logo.png';
import { RotatingLines } from 'react-loader-spinner';

const WelcomeScreen = () => {
  return (
    <>
      <div className="welcome_div">
        <RotatingLines
          strokeColor="#104861"
          strokeWidth="5"
          animationDuration="0.75"
          width="80"
          visible={true}
        />
      </div>
    </>
  )
}

export default WelcomeScreen
