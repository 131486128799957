import { combineReducers } from "redux";
import patient from "./patient";
import auth from "./auth";
import notification from "./notification";
import header from "./header";
import common from "./common";
import meeting from "./meeting";
import task from "./task";

const root = combineReducers({
    auth,
    common,
    patient,
    notification,
    header,
    meeting,
    task
})
export default root;