import { FORMS_DATA, GET_BANNER, GET_PATIENT_LIST ,PHYCHI_FORMS_FIELDS} from "../type.js";

const INIT_STATE = {
    get_banner: null,
    get_patient_list: null,
    form_data: null,
    phychi_form_fields: null,
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PATIENT_LIST:
            if (payload?.status) {
                return {
                    ...state,
                    get_patient_list: payload
                }
            }
        case GET_BANNER:
            if (payload?.status) {
                return {
                    ...state,
                    get_banner: payload
                }
            }
        case FORMS_DATA:
            if (payload?.status) {
                return {
                    ...state,
                    form_data: payload
                }
            }
            case PHYCHI_FORMS_FIELDS:
                if (payload?.status) {
                    return {
                        ...state,
                        phychi_form_fields: payload
                    }
                }
        default:
            return state;
    }
}
