import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

// Hospital login
export const hospitalLogin = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/hospital/login`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const verifyHospitalEmail = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/send_verification_email`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Google Authenticator
export const enableHospitalAuth = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/hospital/enable_2fa`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const verifyHospitalAuth = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/hospital/verify_2fa`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}
export const hospitalReset = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/hospital/reset_password`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}
// family forgot password
export const hospitalForgot = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/hospital/forgot_password`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Hospital logout
export const hospitalLogout = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/hospital/logout`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}