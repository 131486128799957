import React, { useEffect, useState } from 'react';
import taskVector from '../../../assets/facilityimg/task.png';
import { useDispatch, useSelector } from 'react-redux';
import { listSelfEmissionWizard, deleteWizardData } from '../../../redux/action/patient/form';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Popconfirm } from "antd";
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Pagination, Spin, Tag } from 'antd';

const SelfEmissionWizard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [wizardData, setWizardData] = useState([]);
    const [singleWizardData, setSingleWizardData] = useState([]);
    const [modalview, setModal] = useState(false);
    const userData = useSelector(state => state?.auth?.get_user);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
        position: ['bottomRight'],
    });

    useEffect(() => {
        getData();
    }, [pagination.current, pagination.pageSize]);

    const getData = () => {
        const successRes = (res) => {
            setLoading(false)
            setWizardData(res?.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                total: res?.other?.total
            }));
        };
        const failureRes = (res) => {
            setWizardData(res?.data)
            setLoading(false)
        };
        let data = {
            patient_id: userData?._id,
            page: pagination.current,
            limit: pagination.pageSize
        };
        console.log(data)
        dispatch(listSelfEmissionWizard(data, successRes, failureRes));
    };

    const delConfirm = (_id) => {
        new Promise((resolve) => {
            const successCb = (res) => {
                toast.success(res?.message);
                getData();
            };
            const failureCb = (res) => {
                console.log(res, "failure")
            };
            let payload = {
                _id,
                status: 2,
            };
            dispatch(deleteWizardData(payload, successCb, failureCb));
        });
    };

    const handlePageChange = (page, pageSize) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
    };

    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className={"card p-4"}>
                        <div className="row gy-4 d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-xl-3">
                                <div className="card_div" style={{ background: "#edf5fb" }}>
                                    <div className="content">
                                        <b style={{ fontFamily: "Roboto, sans-serif", color: "#104861" }} className="medium_font">
                                            Patient Self Emission Wizard
                                        </b>
                                        <br />
                                        <b style={{ fontFamily: "Roboto, sans-serif", color: "#104861" }} className="medium_font">{wizardData?.length}</b>
                                    </div>
                                    <img src={taskVector} alt="" width="40%" />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-xl-3 ">
                                <button onClick={() => navigate('/self_emission_wizard_add')} className="common_btn medium_font" style={{ borderRadius: "50px", float: "right" }}>
                                    Add Self Emission Wizard
                                </button>
                            </div>
                        </div>

                        {/* Table  */}
                        <Spin spinning={loading} indicator={<LoadingOutlined />}>
                            <div className='w-100' style={{ overflow: "auto" }}>
                                <div className="table_width">
                                    <div className='w-100' style={{ overflow: "auto" }}>
                                        <div className="table_width">
                                            <table className='text-center table-striped mt-5 w-100'>
                                                <thead className='table_div'>
                                                    <tr>
                                                        <th className='text-center px-2'>#</th>
                                                        <th>Suicidal</th>
                                                        <th>Hospital</th>
                                                        <th>Admission date</th>
                                                        <th>Transport</th>
                                                        <th>Menu</th>
                                                        <th>Doctor</th>
                                                        <th>Sitter</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {wizardData && wizardData?.length > 0 ? wizardData.map((no, index) => (
                                                        <tr key={index}>
                                                            <td> {index + 1}</td>
                                                            <td> {no?.suicidal_call_911}</td>
                                                            <td>{no?.hospital_id?.name}</td>
                                                            <td>{no?.admission_date}</td>
                                                            <td>{no?.transport_to_come}</td>
                                                            <td>{no?.menu?.text}</td>
                                                            <td>{no?.provider_id?.first_name} {no?.provider_id?.last_name}</td>
                                                            <td>{no?.select_sitter_id?.first_name} {no?.select_sitter_id?.last_name}</td>
                                                            <td>
                                                                <Button variant="ghost" onClick={() => { setModal(true); setSingleWizardData(no) }} size="sm" className='me-2  my-1'>View</Button>
                                                                <Button variant="ghost" onClick={() => navigate('/self_emission_wizard_edit?id=' + no?._id)} size="sm" className='me-2  my-1'>Edit</Button>
                                                                <Popconfirm
                                                                    title="Delete"
                                                                    description="Are you sure want to delete this data?"
                                                                    onConfirm={() => delConfirm(no?._id)}
                                                                    icon={
                                                                        <QuestionCircleOutlined
                                                                            style={{
                                                                                color: 'red',
                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    <span style={{ cursor: 'pointer' }}>Delete</span>
                                                                </Popconfirm>
                                                            </td>
                                                        </tr>
                                                    )) : <div style={{ textAlign: "center", width: "340%", marginTop: "20px" }}>No Data Found</div>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <Pagination
                                    {...pagination}
                                    onChange={handlePageChange}
                                    showQuickJumper={false}
                                    position={['bottomRight']}
                                    style={{ float: 'right' }}
                                />
                            </div>
                        </Spin>
                        <Modal title="Self Emission Wizard" open={modalview} footer={null} onCancel={() => setModal(false)}>

                            <div className="collapse-contents">
                                <div
                                    className="w-100"
                                    style={{
                                        border: "1px dashed #2666b6",
                                        padding: "10px",
                                        borderRadius: "4px",
                                        height: "600px",
                                        overflowY: "scroll",
                                        overflowX: "hidden"
                                    }}
                                >
                                    <div>
                                        <div
                                            className="text-start"
                                        >
                                            <div className="row g-4 d-flex align-items-center">
                                                <div className="col-md-12">
                                                    <div className=" extra_padding" style={{ height: "100%" }}>
                                                        <div className="row gy-3">
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Suicidal</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.suicidal_call_911}
                                                                />
                                                                <div className="pt-2">
                                                                    {singleWizardData?.suicidal_call_911 === 'Yes' ? "Call 911" : ''}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'> Which Hospital   </label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.hospital_id?.name}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Admission Date</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.admission_date}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Discharge Date</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.discharge_date}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Transport to come</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.transport_to_come}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>When Discharged Were to go</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.when_discharged_were_to_go}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Transport back home</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.transport_back_home}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Menu</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.menu?.text}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Medication</label>
                                                                <br />
                                                                {singleWizardData?.medication && singleWizardData?.medication.map((item, index) => (
                                                                    <Tag key={index} style={{ marginTop: "5px", cursor: "pointer" }}>
                                                                        {item}
                                                                    </Tag>
                                                                ))}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>PRN Medication</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="prn_medication"
                                                                    readOnly
                                                                    value={singleWizardData?.prn_medication}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Choose Program</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.role_id?.role}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Doctor</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.provider_id?.first_name + " " + singleWizardData?.provider_id?.last_name}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className='small_font'>Sitter</label>
                                                                <input
                                                                    className='input_fields'
                                                                    name="medication"
                                                                    readOnly
                                                                    value={singleWizardData?.select_sitter_id?.first_name + " " + singleWizardData?.select_sitter_id?.last_name}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelfEmissionWizard;
