import React from 'react';
import { ToastContainer } from 'react-toastify';
const Toaster = () => {
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        theme="colored"
      />
    </>
  )
}
export default Toaster;