import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

// family profile Data
export const hospitalProfile = (successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("hospital_token")}` },
        url: `${baseUrl}facilities/hospital/get_hospital`,
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}
