import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

// family main login auth
export const familyLogin = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}family_members/login`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const verifyFamilyEmail = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}family_members/send_verification_email`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Google Authenticator
export const enableFamilyAuth = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}family_members/enable_2fa`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const verifyFamilyAuth = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}family_members/verify_2fa`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Family logout
export const familyLogout = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}family_members/logout`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Upload family media
export const uploadFamilyMedia = (data, successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'mutlipart/form-data' },
        url: `${baseUrl}family_members/upload_media`,
        data,
    };
    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCallback(response?.data)
        }
    } catch (error) {
        failureCallback(error)
    }
};