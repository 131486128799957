import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";

/* Super Admin Api */
export const getSuperAdminApi = (url, data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("super_token")}` },
        url: `${baseUrl}${url}`,
    }
    if (data) {
        config.data = data
    }
    const response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data)
        } else {
            failureCb(response)
            console.log("ERROR", response)
        }
    } catch (error) {
        failureCb(error)
        console.log("ERROR", error)
    }
}

/* Upload Super Admin Media */
export const uploadAdminMedia = (data, successCb, failureCb) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("super_token")}` },
        url: `${baseUrl}administration/upload_media`,
        data
    }
    const response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data)
        } else {
            failureCb(response)
            console.log("ERROR", response)
        }
    } catch (error) {
        failureCb(error)
        console.log("ERROR", error)
    }
}