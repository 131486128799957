import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
import { STREAM_USERS, PROVIDER_ROLES, PROVIDER_DEPARTMENTS, MEETING_CHANNEL, MEETING_TOKEN, IS_STREAM_MINIMIZE, IN_MEETING, USER_TYPE, MYUID, AUDIENCE_MEMBERS, HOST_MEMBERS } from "../../type";

export const createAgoraToken = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}common/create_agora_token`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const setMeetingChannel = (data) => async (dispatch) => {
    dispatch({
        type: MEETING_CHANNEL,
        payload: data
    });
}

export const setMeetingToken = (data) => async (dispatch) => {
    dispatch({
        type: MEETING_TOKEN,
        payload: data
    });
}

export const setStreamUsers = (data) => async (dispatch) => {
    dispatch({
        type: STREAM_USERS,
        payload: data
    });
}

export const setAudienceMembers = (data) => async (dispatch) => {
    dispatch({ type: AUDIENCE_MEMBERS, payload: data });
}

export const setHostMembers = (data) => async (dispatch) => {
    dispatch({ type: HOST_MEMBERS, payload: data });
}

export const resetRoles = () => async (dispatch) => {
    dispatch({
        type: PROVIDER_ROLES,
        payload: []
    });
}

export const resetDepartments = () => async (dispatch) => {
    dispatch({
        type: PROVIDER_DEPARTMENTS,
        payload: []
    });
}

export const setMyUID = (data) => async (dispatch) => {
    dispatch({
        type: MYUID,
        payload: data
    });
}

export const getOtherFacilities = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/get_other_facilities`,
        data
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = res?.data?.data
    }
    dispatch({ type: HOST_MEMBERS, payload });
    dispatch({ type: AUDIENCE_MEMBERS, payload });
}

export const getMyFacilityMember = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/get_facility_and_its_sub_admins`,
        data
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = res?.data?.data
    }
    if (callBack) {
        callBack(res?.data)
    } else {
        dispatch({ type: HOST_MEMBERS, payload });
        dispatch({ type: AUDIENCE_MEMBERS, payload });
    }
}

export const getProviderRoles = () => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}providers/get_provider_roles`,
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = res?.data?.data
    }
    dispatch({
        type: PROVIDER_ROLES,
        payload
    });
}

export const getProvidersByRole = (data, my_id) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/get_providers_by_role`,
        data
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = res?.data?.data?.filter((prov) => prov?._id != my_id)
    }
    dispatch({ type: HOST_MEMBERS, payload });
    dispatch({ type: AUDIENCE_MEMBERS, payload });
}

export const getProviderDepartments = () => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/get_provider_departments`,
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = res?.data?.data
    }
    dispatch({
        type: PROVIDER_DEPARTMENTS,
        payload
    });
}

export const getProvidersByDepartment = (data) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/get_providers_by_department`,
        data
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = res?.data?.data
    }
    dispatch({ type: HOST_MEMBERS, payload });
    dispatch({ type: AUDIENCE_MEMBERS, payload });
}

export const uploadFacilityProfile = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'multipart/form-data' },
        url: `${baseUrl}facilities/upload_media`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const setIsStreamMinimize = (data) => async (dispatch) => {
    dispatch({
        type: IS_STREAM_MINIMIZE,
        payload: data
    });
}

export const setInMeeting = (data) => async (dispatch) => {
    dispatch({
        type: IN_MEETING,
        payload: data
    });
}

export const setUserType = (data, callBack) => async (dispatch) => {
    dispatch({
        type: USER_TYPE,
        payload: data
    });
    callBack()
}

export const aquireRecording = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/aquire_recording`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const startRecording = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/start_recording`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const updateRecording = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/update_recording`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const stopRecording = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/stop_recording`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

// not in used so far
export const getAllPatients = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}providers/get_patient_for_meeting`,
        data
    }
    const res = await apiCall(config, dispatch);
    if (res?.data?.status) {
        callBack(res?.data?.data)
    } else {
        callBack([])
    }
}

export const getPatientFamilyMembers = (data, callBack, fields) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/patient/get_family_members`,
        data
    }
    const res = await apiCall(config, dispatch);
    let payload = []
    if (res?.data?.status) {
        payload = [...res?.data?.data, fields]
    }
    else {
        payload = [fields]
    }
    if (callBack) {
        callBack(res?.data)
    } else {
        dispatch({ type: HOST_MEMBERS, payload });
        dispatch({ type: AUDIENCE_MEMBERS, payload });
    }
}

export const getProviderPatients = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}common/create_agora_token`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const getFamilyMemberByPatient = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}common/create_agora_token`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const getPatientPaymentInfo = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/get_patient_payment_info`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const addSOSRecord = (data) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/add_sos_call_record`,
        data
    }
    await apiCall(config, dispatch);
}

export const updateSOSRecord = (data) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}facilities/update_sos_call_record`,
        data
    }
    await apiCall(config, dispatch);
}

export const createChargePayment = (data, callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}common/create_charge_payment`,
        data
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}

export const createSOSInvoice = (callBack) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("patient_token")}` },
        url: `${baseUrl}patients/create_sos_invoice`
    }
    const res = await apiCall(config, dispatch);
    callBack(res?.data)
}