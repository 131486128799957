import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
import { GET_USER } from "../../type";

// Provider Profile details
export const providerProfile = (successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('provider_token')}` },
        url: `${baseUrl}providers/get_provider_detail`,
    }
    const res = await apiCall(config, dispatch);
    try {       
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// update profile Data
export const updateProviderProfile = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("provider_token")}` },
        url: `${baseUrl}providers/update`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
            dispatch({
                type: GET_USER,
                payload: res?.data?.data
            })
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Change password
export const providerChangePassword = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("provider_token")}` },
        url: `${baseUrl}providers/change_password`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res?.data)
        } else {
            failureRes(res?.data)
        }
    } catch (error) {
        failureRes(error)
    }
}