import { USER_EXIST, IS_TASK_UPDATE } from "../type";

const INIT_STATE = {
    is_user_exist: true,
    is_task_update: false,
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_EXIST:
            return {
                ...state,
                is_user_exist: payload,
            };
        case IS_TASK_UPDATE:
            return {
                ...state,
                is_task_update: payload,
            };
        default:
            return state;
    }
};