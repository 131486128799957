import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminUrl, fileUrl, webUrl } from '../Constant/url';
import { AGORA_APP_ID } from '../Constant/creds';
import { collection, onSnapshot, getDoc, updateDoc, doc, addDoc, query, orderBy, getDocs, setDoc, where } from "firebase/firestore";
import { db } from '../firebase';
import { Input, Modal, Spin, Image } from "antd"
import { SendOutlined, LoadingOutlined, CopyOutlined, UploadOutlined } from "@ant-design/icons"
import {
    setStreamUsers,
    setIsStreamMinimize,
    setInMeeting,
    aquireRecording,
    startRecording,
    updateRecording,
    stopRecording,
    setMyUID,
    setMeetingChannel,
    setMeetingToken,
    updateSOSRecord,
    getPatientPaymentInfo,
    createChargePayment,
    createSOSInvoice,
} from '../redux/action/facility/meeting';
import { store } from "../store/store.js";
import { toast } from 'react-toastify';
import $ from 'jquery';
import moment from 'moment';
import AgoraRTC from 'agora-rtc-sdk-ng';
import mute_filled from "../assets/icons/mute-filled.png"
import unmute_filled from "../assets/icons/unmute-filled.png"
import camera_on from "../assets/icons/camera-on.png"
import camera_off from "../assets/icons/camera-off.png"
import end_call from "../assets/icons/end_call.png"
import screen_sharing_filled from "../assets/icons/screen-sharing-filled.png"
import stop_sharing_filled from "../assets/icons/stop-sharing-filled.png"
import start_recording from "../assets/icons/start-recording.png"
import stop_recording from "../assets/icons/stop-recording.png"
import addUser from "../assets/icons/user-settings.png"
import noImage from "../assets/no-image.jpg"
import virtualBack from "../assets/virtual_back.png"
import pdficon from '../assets/icons/pdf_file.png';

import VirtualBackgroundExtension, { IVirtualBackgroundProcessor } from "agora-extension-virtual-background";
import wasm from "agora-extension-virtual-background/wasms/agora-wasm.wasm?url";
import { uploadMediaStreaming } from '../redux/action/auth/notification.js';

const client = AgoraRTC.createClient({ mode: "live", codec: "vp9" });

const Streaming = (props) => {
    const userType = props?.userType ?? 'Facility'
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const mainCaller = useRef(null)
    const mainViewFacility = useRef(null)
    const screenShareRef = useRef(null)
    const [screenMode, setScreenMode] = useState(null)
    const providerChat = useRef(null)
    const screenShareTrackRef = useRef(null)
    const messageRef = useRef(null)
    const meetingChannelRef = useRef(null)
    const userData = useSelector((state) => state?.auth?.get_user);
    const { meetingChannel, meetingToken, in_meeting, user_type, myUID } = useSelector((state) => state?.meeting);
    const [meetingData, setMeetingData] = useState(null)
    const [recordingLoader, setRecordingLoader] = useState(false)
    const [localTrack, setLocalTrack] = useState([])
    const [messageText, setMessageText] = useState('')
    const [isText, setIsText] = useState(false)
    const [messages, setMessages] = useState([])
    const [mic, setMic] = useState(true)
    const [camera, setCamera] = useState(true)
    const [loading, setLoading] = useState(false);
    const isStreamMinimize = useSelector((state) => state.meeting.is_stream_minimize)
    // meeting update
    const [meetingLinkModal, setMeetingLinkModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false);
    const [meetingInviteLink, setMeetingInviteLink] = useState('');

    const [isVirtualBackground, setVirtualBackground] = useState(false);
    const extension = useRef(new VirtualBackgroundExtension());
    const processor = useRef();
    // const [selectedOption, setSelectedOption] = useState("");

    const checkCompatibility = () => {
        console.log("function called")
        if (!extension.current.checkCompatibility()) {
            console.error("Does not support virtual background!");
            return;
        }
    }

    const imageBackground = async (imageString) => {
        const image = new Image();
        image.onload = async () => {
            processor.current?.setOptions({ type: "img", source: image });
            await processor.current.enable();
            setVirtualBackground(true)
        };
        image.src = imageString;
    };

    // const blurBackground = () => {
    //     processor.current?.setOptions({ type: "blur", blurDegree: 2 });
    // };

    const disableVirtualBackground = async () => {
        await processor.current?.disable();
        setVirtualBackground(false)
    };

    useEffect(() => {
        const initializeVirtualBackgroundProcessor = async () => {
            AgoraRTC.registerExtensions([extension.current]);
            checkCompatibility();
            if (localTrack.videoTrack) {
                console.log("Initializing virtual background processor...");
                try {
                    processor.current = extension.current.createProcessor();
                    await processor.current.init(wasm);
                    localTrack.videoTrack.pipe(processor.current).pipe(localTrack.videoTrack.processorDestination);
                } catch (error) {
                    console.error("Error initializing virtual background:", error);
                }
            }
        };
        void initializeVirtualBackgroundProcessor();
        return () => {
            disableVirtualBackground();
        };
    }, [localTrack.videoTrack]);

    const handleVirtualBGSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                changeBackground(base64String)
            }
            reader.readAsDataURL(selectedFile);
        } else {
            alert('Please select a valid image file.');
        }
    }

    const changeBackground = (imageString) => {
        if (!processor.current) {
            console.error("Virtual background processor not initialized");
            return;
        }
        imageBackground(imageString);
        // // Apply selected option settings here
        // switch (selectedOption) {
        //     case "blur":
        //         setSelectedOption(selectedOption);
        //         blurBackground();
        //         break;
        //     case "image":

        //         break;
        //     default:
        //         console.error("Invalid option:", selectedOption);
        // }
    };

    const joinMeeting = async () => {
        if (user_type == "host") {
            let joinResponse = await Promise.all([
                client.join(AGORA_APP_ID, meetingChannel, meetingToken),
                AgoraRTC.createMicrophoneAudioTrack(),
                AgoraRTC.createCameraVideoTrack(),
            ]);
            await client.setClientRole('host');
            dispatch(setMyUID(joinResponse[0]))
            let myTracks = {
                audioTrack: joinResponse[1],
                videoTrack: joinResponse[2]
            }
            setLocalTrack(myTracks)
            myTracks?.videoTrack?.play(mainCaller?.current)
            await client.publish(Object.values(myTracks));
        } else {
            let joinResponse = await Promise.all([
                client.join(AGORA_APP_ID, meetingChannel, meetingToken)
            ]);
            await client.setClientRole('audience');
            dispatch(setMyUID(joinResponse[0]))
            let myTracks = {
                audioTrack: null,
                videoTrack: null
            }
            setLocalTrack(myTracks)
        }
        dispatch(setInMeeting(true))
    }

    const manageChannelDoc = async (uid, user_id) => {
        try {
            const docRef = doc(db, "meeting", meetingChannel);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let myUserType = ''
                if (userType == 'Facility') {
                    if (userData?.user_type == "super") {
                        myUserType = 'Facility (Main)'
                    } else {
                        myUserType = 'Facility (Sub Admin)'
                    }
                } else if (userType == 'Provider') {
                    if (userData?.provider_department == "Provider") {
                        myUserType = `Provider (${userData?.role})`
                    } else {
                        myUserType = `Staff Member (${userData?.provider_department})`
                    }
                } else {
                    myUserType = userType
                }
                let object = {
                    uid,
                    name: (userData?.name || `${userData?.first_name} ${userData?.last_name}`),
                    profile_pic: (userData?.image || userData?.profile_pic),
                    _id: user_id,
                    is_left: 0,
                    leave_time: 0,
                    connected_time: moment().unix(),
                }
                if (user_type == "audience") {
                    const subcollectionRefB = collection(docRef, 'broadcasters');
                    const condition = where('is_left', '==', 0);
                    const queryRef = query(subcollectionRefB, condition);
                    getDocs(queryRef).then((querySnapshot) => {
                        if (querySnapshot?.size == 0) {
                            toast.error("No Host is in the meeting, Wait for any host to join")
                            dispatch(setMeetingChannel(null))
                            dispatch(setMeetingToken(null))
                            dispatch(setInMeeting(false))
                            return false
                        }
                        const subcollectionRef = collection(docRef, 'audience');
                        const customDocRef = doc(subcollectionRef, uid?.toString());
                        setDoc(customDocRef, object)
                    })
                } else {
                    object.audio = true
                    object.video = true
                    object.user_type = myUserType
                    // Create the subcollection document with the custom ID
                    const subcollectionRef = collection(docRef, 'broadcasters');
                    const customDocRef = doc(subcollectionRef, user_id);
                    setDoc(customDocRef, object)
                }
            } else {
                console.log("I'm here in error @ 135!!! Please check me...")
                return false
            }
        } catch (errrr) {
            console.log("meeting---------->>>>>>>> in catch", errrr)
        }
    }

    const handleUserPublished = async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        let users = store.getState().meeting.stream_users
        let userData = users
        let existance = users?.filter((u) => u?.uid == user?.uid)
        if (existance?.length == 0) {
            userData.push(user)
            dispatch(setStreamUsers(userData))
            getStoreInfo(userData)
        } else {
            getStoreInfo(userData)
        }
    }

    const mapVideoTracks = (videoTracks, mData) => {
        try {
            if (mData && videoTracks?.length > 0) {
                let is_screen_shared = mData?.is_screen_shared
                let shared_by = mData?.shared_by
                for (let v = 0; v < videoTracks?.length; v++) {
                    let track = videoTracks[v]
                    let user = track?.user
                    if (is_screen_shared) {
                        if (shared_by != myUID) {
                            setTimeout(() => {
                                user?.videoTrack?.play(screenShareRef?.current)
                            }, 1000)
                        } else {
                            setTimeout(() => {
                                user?.videoTrack?.play("other_caller_video_" + user?.uid);
                                user?.audioTrack?.play()
                            }, 1000)
                        }
                    } else {
                        setTimeout(() => {
                            user?.videoTrack?.play("other_caller_video_" + user?.uid);
                            user?.audioTrack?.play()
                        }, 1000)
                    }
                }
            }
        } catch (err) {
            console.log("error", err)
        }
    }

    const handleUserUnpublished = async (user) => {
        //remove from stream users redux
        let users = store.getState().meeting.stream_users
        let userData = users
        let existanceIdx = users?.findIndex((u) => u?.uid == user?.uid)
        if (existanceIdx >= 0) {
            userData.splice(existanceIdx, 1)
            dispatch(setStreamUsers(userData))
        }
        // set to is live 0 in broadcaster collection
        const docRef = doc(db, "meeting", meetingChannelRef.current);
        const subcollectionRef = collection(docRef, 'broadcasters');
        const condition = where('uid', '==', user?.uid);
        const queryRef = query(subcollectionRef, condition);
        getDocs(queryRef).then(async (querySnapshot) => {
            querySnapshot.forEach((docSnap) => {
                let unsubUserDocId = docSnap.id
                let unsubUserDocData = docSnap.data();
                let editObj = {
                    ...unsubUserDocData,
                    is_left: 1,
                    leave_time: moment().unix()
                }
                const customDocRef = doc(subcollectionRef, unsubUserDocId);
                setDoc(customDocRef, editObj)
            })
            // if that user is doing screen share
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let meeting_data = docSnap?.data()
                if (meeting_data?.is_screen_shared && meeting_data?.shared_by == user?.uid) {
                    meeting_data = {
                        ...meeting_data,
                        is_screen_shared: false,
                        shared_by: ''
                    }
                    await updateDoc(docRef, meeting_data)
                }
            }
        })
        getStoreInfo(userData)
    }

    const endByAudience = async (uid) => {
        try {
            const meetingDocRef = doc(db, "meeting", meetingChannelRef.current);
            const audienceCollectionRef = collection(meetingDocRef, 'audience');
            const condition = where("uid", "==", uid)
            const queryRef = query(audienceCollectionRef, condition);
            let audienceDocs = await getDocs(queryRef)
            audienceDocs.forEach(async (audienceDoc) => {
                let audienceDocData = audienceDoc?.data()
                const updatedData = { ...audienceDocData, is_left: 1, leave_time: moment().unix() };
                const docToUpdateRef = doc(audienceCollectionRef, audienceDoc?.id);
                await updateDoc(docToUpdateRef, updatedData);
            })
            client.leave()
            dispatch(setInMeeting(false))
            let urlValue = window.location.href
            if (urlValue?.indexOf("meeting") > 0) {
                window.location.href = adminUrl + "/"
            } else {
                window.location.reload()
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }
    }

    const displayUsers = async (videoTracks, mData) => {
        try {
            let items = ""
            const docRef = doc(db, "meeting", meetingChannelRef.current);
            const subcollectionRef = collection(docRef, 'broadcasters');
            const condition1 = where('is_left', '==', 0);
            const queryRef = query(subcollectionRef, condition1);
            getDocs(queryRef).then((querySnapshot) => {
                querySnapshot.forEach((userSnapshot) => {
                    let user = userSnapshot.data()
                    console.log("user", user?.name)
                    if (user?._id != userData?._id) {
                        items = items + `
                        <div class="mb-3 p-0" id="caller_${user?.uid}" style="position:relative;overflow:hidden;border-radius: 5px;width:20%;border:1px solid lightgray;margin-left:1%;height:120px;background-image:url('${fileUrl}${user?.profile_pic}');background-size:50%;background-repeat:no-repeat;background-position:center 65%;text-align:center">
                            <p style="position: absolute;z-index: 1;background: rgba(0,0,0,0.3);color:white;height:20px;line-height:20px;font-size: 14px;width: 100%">
                                ${user?.name ?? ""}
                            </p>
                            <div class='row m-0' id='other_caller_video_${user?.uid}' style='width:100%;height:100%'>
                            </div>
                        </div>`
                    }
                })
                if (items != "") {
                    items = `<p class='text-center'>Other Members</p>` + items
                }
                $("#other_callers").html(items)
                mapVideoTracks(videoTracks, mData)
            })
        } catch (e) {
            console.log("I am here with e", e)
        }
    }

    const renderControls = (type) => {
        if (meetingData) {
            if (type == "screen_share") {
                if (meetingData?.is_screen_shared && meetingData?.shared_by == myUID) {
                    return true
                } else {
                    return false
                }
            }
            if (type == "recording") {
                if (meetingData?.is_recording && meetingData?.recorded_by == myUID) {
                    return true
                } else {
                    return false
                }
            } else {
                const docRef = doc(db, "meeting", meetingChannelRef.current);
                const subcollectionRef = collection(docRef, 'broadcasters');
                const customDocRef = doc(subcollectionRef, userData?._id);
                getDoc(customDocRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        let data = docSnap.data();
                        return data[type]
                    }
                })
            }
        }
    }

    const callAborted = async (user_id) => {
        try {
            let meeting_data = null
            const docRef = doc(db, "meeting", meetingChannelRef.current);
            // if that user is doing screen share
            const docSnapp = await getDoc(docRef);
            if (docSnapp.exists()) {
                meeting_data = docSnapp?.data()
                if (meeting_data?.is_screen_shared == true && meeting_data?.shared_by == myUID) {
                    stopScreenSharing()
                }
            }
            const subcollectionRef = collection(docRef, 'broadcasters');
            const customDocRef = doc(subcollectionRef, user_id);
            getDoc(customDocRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                    const broadcasterDoc = docSnap.data();
                    let editObj = {
                        ...broadcasterDoc,
                        is_left: 1,
                        leave_time: moment().unix()
                    }
                    setDoc(customDocRef, editObj)
                    await client.leave()
                    localTrack?.videoTrack?.setEnabled(false)
                    localTrack?.audioTrack?.setEnabled(false)
                    dispatch(setInMeeting(false))
                    dispatch(setMeetingChannel(null))
                    dispatch(setMeetingToken(null))
                    // in case of sos
                    if (meeting_data?.is_sos && meeting_data?.host_id == user_id) {
                        // add call duration in sos
                        let sosEditObj = {
                            sos_channel_name: meetingChannelRef.current,
                            call_duration: moment().unix() - meeting_data?.meeting_date
                        }
                        dispatch(updateSOSRecord(sosEditObj))
                        let editMData = {
                            ...meeting_data,
                            is_live: 0
                        }
                        updateDoc(docRef, editMData)
                    }
                    setTimeout(() => {
                        let urlValue = window.location.href
                        if (urlValue?.indexOf("meeting") > 0) {
                            window.location.href = adminUrl + "/"
                        } else {
                            window.location.reload()
                        }
                    }, 2000)
                }
            })
        } catch (er) {
            console.log("querySnapshot.size ", er)
        }
    }

    const getStoreInfo = async (users) => {
        try {
            let tracks = []
            const docRef = doc(db, "meeting", meetingChannelRef.current);
            const subcollectionRef = collection(docRef, 'broadcasters');
            getDocs(subcollectionRef).then(async (querySnapshot) => {
                querySnapshot.forEach((elementSnap) => {
                    let element = elementSnap.data()
                    let a = users?.findIndex((data) => (data?.uid == element?.uid && element?.is_left == 0))
                    if (a != -1) {
                        tracks.push({
                            user: users[a]
                        })
                    }
                });
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let mData = docSnap.data()
                    displayUsers(tracks, mData)
                }
            })
        } catch (err) {
            console.log("in catch @ getStoreInfo", err)
        }
    }

    const sendMessage = async (file = '', fileType = '') => {
        if (messageText === "" && file ==='') {
            toast.error("Please enter message or select file!")
            return;
        }
            setIsText(true)
            const docRef = doc(db, "meeting", meetingChannel)
            const messagingRef = collection(docRef, 'message')
            addDoc(messagingRef, {
                msgID: myUID,
                msgName: userData?.name ? userData?.name : `${userData?.first_name} ${userData?.last_name}`,
                msgPic: userData?.image ? fileUrl + userData?.image : fileUrl + userData?.profile_pic,
                message: messageText,
                msgCreatedAt: moment().unix(),
                file,
                fileType
            }).then(() => {
                setMessageText('')
                setIsText(false)
            });
        
    }

    const renderRecordingLoader = () => {
        return (
            <li style={{ display: "inline", marginLeft: '10px' }}>
                <LoadingOutlined
                    style={{
                        fontSize: 24,
                        color: 'white'
                    }}
                />
            </li>
        )
    }

    const storeCountOnMainDoc = async (collectionType) => {
        let mData
        const docRef = doc(db, "meeting", meetingChannelRef.current);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            mData = docSnap.data()
            const subcollectionRef = collection(docRef, collectionType);
            const condition = where('is_left', '==', 0);
            const queryRef = query(subcollectionRef, condition);
            getDocs(queryRef).then((querySnapshot) => {
                let count = querySnapshot?.size
                // update to main collection
                let editObj = {
                    ...mData
                }
                if (collectionType == "broadcasters") {
                    editObj.activebroadcasters = count
                    if (count == 0) {
                        editObj.is_live = 0
                    }
                } else if (collectionType == "audience") {
                    editObj.activeAudience = count
                }
                updateDoc(docRef, editObj)
            })
        }
    }

    const manageCamera = async () => {
        let isCamera = localTrack.videoTrack?.enabled
        setCamera(!isCamera)
        localTrack?.videoTrack?.setEnabled(!isCamera)
        if (isCamera) {
            while (mainCaller.current.firstChild) {
                mainCaller.current.removeChild(mainCaller.current.firstChild);
            }
            const newChild = document.createElement('div');
            newChild.id = 'mainCameraThumb'
            newChild.style.height = '500px'
            newChild.style.background = `url(${fileUrl}${userData?.image ? userData?.image : userData?.profile_pic})`
            newChild.style.backgroundSize = 'contain'
            newChild.style.backgroundPosition = 'center'
            newChild.style.backgroundRepeat = 'no-repeat'
            mainCaller.current.appendChild(newChild);
        } else {
            let mainCameraThumb = document.getElementById('mainCameraThumb')
            mainCameraThumb.remove()
        }
    }

    const muteUnmute = async () => {
        let isMic = localTrack.audioTrack?.enabled
        setMic(!isMic)
        localTrack?.audioTrack?.setEnabled(!isMic)
    }

    const handleScreenSharing = async () => {
        const docRef = doc(db, "meeting", meetingChannel);
        let is_screen_shared = meetingData?.is_screen_shared ?? false
        if (is_screen_shared) {
            if (meetingData?.shared_by != myUID) {
                toast.error("Screen is already presented by another user !!! ")
                return false
            }
        }
        if (is_screen_shared) {
            if (screenShareTrackRef) {
                await client.unpublish(screenShareTrackRef.current);
            }
            if (localTrack.audioTrack?.enabled) {
                await client.publish(localTrack?.audioTrack);
            }
            if (localTrack.videoTrack?.enabled) {
                await client.publish(localTrack?.videoTrack);
            }
            screenShareTrackRef?.current?.stop();
            screenShareTrackRef?.current?.close();
            if (meetingData?.is_recording) {
                updateRecordingApi()
            }
        } else {
            let screenTrack = await AgoraRTC.createScreenVideoTrack({
                name: meetingChannel,
                encoderConfig: {
                    framerate: 30,
                    height: 720,
                    width: 1280,
                },
                optimizationMode: "detail"
            })
            screenShareTrackRef.current = screenTrack
            if (localTrack.videoTrack?.enabled) {
                await client.unpublish([localTrack?.videoTrack]);
            }
            await client.publish(screenTrack);
            screenTrack?.on("track-ended", () => stopScreenSharing())
        }
        let editObj = {
            ...meetingData,
            is_screen_shared: !is_screen_shared,
            shared_by: myUID
        }
        await updateDoc(docRef, editObj)
        setTimeout(() => {
            if (meetingData?.is_recording) {
                updateRecordingApi()
            }
        }, 3000)
    }

    const stopScreenSharing = async () => {
        try {
            if (screenShareTrackRef?.current) {
                screenShareTrackRef?.current?.stop();
                screenShareTrackRef?.current?.close();
                if (screenShareTrackRef) {
                    await client.unpublish(screenShareTrackRef?.current);
                }
                if (localTrack.audioTrack?.enabled) {
                    await client.publish(localTrack?.audioTrack);
                }
                if (localTrack.videoTrack?.enabled) {
                    await client.publish(localTrack?.videoTrack);
                }
                const docRef = doc(db, "meeting", meetingChannelRef?.current);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let meeting_data = docSnap.data()
                    let editObj = {
                        ...meeting_data,
                        is_screen_shared: false,
                        shared_by: ''
                    }
                    await updateDoc(docRef, editObj)
                }
                if (meetingData?.is_recording) {
                    updateRecordingApi()
                }
            }
        } catch (err) {
            console.log("In Catch @ 395", err)
        }
    }

    const handleRecording = async () => {
        let is_recording = meetingData?.is_recording ?? false
        if (is_recording) {
            // recording started
            if (meetingData?.recorded_by != myUID) {
                toast.error("Meeting is already recording")
                return false
            } else {
                manageStopRecordingApis()
            }
        } else {
            manageStartRecordingApis()
        }
    }

    const manageStartRecordingApis = async () => {
        setRecordingLoader(true)
        const docRef = doc(db, "meeting", meetingChannel);
        let resourceId = null
        let data = {
            cname: meetingChannel,
            uid: myUID
        }
        const callBackStartRec = async (response) => {
            if (!response?.status) {
                toast.error("Unable to start recording process !! due to some technical error")
                setRecordingLoader(false)
                return false
            }
            let editObj = {
                ...meetingData,
                is_recording: true,
                recorded_by: myUID,
                record_res_id: resourceId,
                sid: response?.data?.sid
            }
            await updateDoc(docRef, editObj)
            setRecordingLoader(false)
        }
        const callBackAquire = (resp) => {
            if (!resp?.status) {
                setRecordingLoader(false)
                toast.error("Unable to start recording !! due to some technical error")
                return false
            }
            resourceId = resp?.data?.resourceId
            let dataObj = {
                cname: meetingChannel,
                uid: myUID,
                resourceId,
                agora_token: meetingToken
            }
            dispatch(startRecording(dataObj, callBackStartRec))
        }
        dispatch(aquireRecording(data, callBackAquire))
    }

    const updateRecordingApi = async () => {
        let data = {
            resourceId: meetingData?.record_res_id,
            sid: meetingData?.sid,
            cname: meetingChannel,
            uid: meetingData?.recorded_by == 0 ? 1 : meetingData?.recorded_by
        }
        const callBackUpdateRec = async (response) => {
            console.log("recording update api response", response)
        }
        dispatch(updateRecording(data, callBackUpdateRec))
    }

    const manageStopRecordingApis = async () => {
        setRecordingLoader(true)
        const docRef = doc(db, "meeting", meetingChannelRef.current);
        const callBackStopRec = async (response) => {
            if (!response?.status) {
                toast.error("Unable to stop recording process !! due to some technical error")
                setRecordingLoader(false)
                return false
            }
            // store recording to firebase
            let recording_files = meetingData?.recording_files ?? []
            let responseData = response?.data
            recording_files.push({
                fileName: responseData?.serverResponse?.fileList[0]?.fileName,
                sliceStartTime: responseData?.serverResponse?.fileList[0]?.sliceStartTime
            })
            let editObj = {
                ...meetingData,
                is_recording: false,
                recorded_by: 0,
                record_res_id: '',
                sid: '',
                recording_files: recording_files ?? []
            }
            await updateDoc(docRef, editObj)
            setRecordingLoader(false)
        }
        let dataObj = {
            cname: meetingChannel,
            uid: myUID,
            resourceId: meetingData?.record_res_id,
            sid: meetingData?.sid
        }
        dispatch(stopRecording(dataObj, callBackStopRec))
    }

    const handleCopyClick = (link) => {
        if (link != '') {
            const textField = document.createElement('textarea');
            textField.innerText = link;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            document.body.removeChild(textField);
            toast.success('Link Copied')
        }
    };

    const checkIfInvitedRenderingProvider = async (addedData) => {
        if (addedData?.user_type?.indexOf("Provider") == 0) {
            const docRef = doc(db, "meeting", meetingChannelRef.current);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let mData = docSnap.data()
                if (mData?.host_id == userData?._id && mData?.is_sos) {
                    let checkedInvites = mData?.invites?.filter((Invite) => Invite == addedData?._id)
                    if (checkedInvites?.length > 0) {
                        // update sos record table
                        let sosEditObj = {
                            sos_channel_name: mData?.meeting_channel,
                            is_provider_connected: "Yes",
                            provider_id: addedData?._id
                        }
                        dispatch(updateSOSRecord(sosEditObj))
                        deductAmount(mData?.meeting_channel)
                    }
                }
            }
        }
    }

    const deductAmount = async (channel_name) => {
        // test cases passed and check patient payment info
        const callBack = (response) => {
            if (response?.status) {
                // ready to deduct payment
                const paymentCallBack = (payResponse) => {
                    if (!payResponse?.status) {
                        // payment failed, now generate Invoice
                        const invCallBack = (invResp) => {
                            if (invResp?.status) {
                                let invSOSEditObj = {
                                    sos_channel_name: meetingChannelRef?.current,
                                    payment_status: invResp?.data?.payment_link,
                                    payment_object: null
                                }
                                dispatch(updateSOSRecord(invSOSEditObj))
                            }
                        }
                        dispatch(createSOSInvoice(invCallBack))
                    }
                }
                dispatch(createChargePayment({ patient_id: userData?._id, sos_channel_name: channel_name }, paymentCallBack))
            } else {
                const invCallBack = (invResp) => {
                    if (invResp?.status) {
                        let invSOSEditObj = {
                            sos_channel_name: meetingChannelRef?.current,
                            payment_status: invResp?.data?.payment_link,
                            payment_object: null
                        }
                        dispatch(updateSOSRecord(invSOSEditObj))
                    }
                }
                dispatch(createSOSInvoice(invCallBack))
            }
        }
        dispatch(getPatientPaymentInfo({ patient_id: userData?._id }, callBack))
    }

    const openFileChooser = () => {
        if (isVirtualBackground) {
            setVirtualBackground(false)
            disableVirtualBackground()
        } else {
            fileInputRef.current.value = null;
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (screenMode) {
            if (screenMode == 'full') {
                mainViewFacility.current.className = 'col-12';
                providerChat.current.style.display = 'none';
                if (screenShareRef) {
                    screenShareRef.current.style.height = '100vh';
                }
            } else if (screenMode == 'normal') {
                mainViewFacility.current.className = 'col-9';
                providerChat.current.style.display = 'block';
                if (screenShareRef) {
                    screenShareRef.current.style.height = '500px';
                }
            }
        }
    }, [screenMode])

    useEffect(() => {
        if (myUID) {
            manageChannelDoc(myUID, userData?._id)
        }
    }, [myUID, userData])

    useEffect(() => {
        if (meetingToken && meetingChannel) {
            meetingChannelRef.current = meetingChannel
            if (!in_meeting) {
                joinMeeting()
            }
            const unsubs = onSnapshot(doc(db, "meeting", meetingChannel), (docSnapShot) => {
                let mData = docSnapShot.data()
                setMeetingInviteLink(mData?.invite_link)
                setMeetingData(mData)
                if (mData?.is_live == 0) {
                    dispatch(setMeetingChannel(null))
                    dispatch(setMeetingToken(null))
                    dispatch(setInMeeting(false))
                    client.leave()
                    let urlValue = window.location.href
                    if (urlValue?.indexOf("meeting") > 0) {
                        window.location.href = adminUrl + "/"
                    } else {
                        window.location.reload()
                    }
                }
                if (screenMode && screenMode == "full" && mData?.is_screen_shared == false) {
                    setScreenMode('normal')
                }
            });
            // snapshot on broadcasters collection
            const docRef = doc(db, "meeting", meetingChannel);
            const subcollectionRef = collection(docRef, 'broadcasters');
            const unsubscribeBroadcasters = onSnapshot(subcollectionRef, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const doc = change.doc;
                    const changedData = doc.data();
                    console.log("change.type", change.type, changedData?.name, changedData?.is_left)
                    if (change?.type == "added") {
                        checkIfInvitedRenderingProvider(changedData)
                    }
                    let users = store.getState().meeting.stream_users
                    getStoreInfo(users)
                    setTimeout(() => {
                        storeCountOnMainDoc('broadcasters')
                    }, 3000)
                })
            })
            // snapshot on audience collection
            const subcollectionRefAudience = collection(docRef, 'audience');
            const unsubscribeAudience = onSnapshot(subcollectionRefAudience, (snapshott) => {
                snapshott.docChanges().forEach((change) => {
                    let users = store.getState().meeting.stream_users
                    getStoreInfo(users)
                    setTimeout(() => {
                        storeCountOnMainDoc('audience')
                    }, 3000)
                })
            })
            // loadLiveChat
            const messageCollectionRef = collection(doc(db, 'meeting', meetingChannel), 'message');
            const orderedQuery = query(messageCollectionRef, orderBy('msgCreatedAt', 'asc'));
            const unsubsLiveChat = onSnapshot(orderedQuery, querySnapshot => {
                let messagesArray = []
                querySnapshot.forEach((docSnapShot) => {
                    messagesArray.push(docSnapShot.data())
                });
                setMessages(messagesArray)
                setTimeout(() => {
                    messageRef.current.scrollTop = messageRef.current.scrollHeight;
                }, 500);
            });
            return () => {
                unsubs()
                unsubscribeBroadcasters()
                unsubscribeAudience()
                unsubsLiveChat()
            }
        }
    }, [meetingToken, meetingChannel])

    useEffect(() => {
        if (meetingData) {
            let users = store.getState().meeting.stream_users
            let userData = users
            getStoreInfo(userData)
            if (screenMode && screenMode == "full" && meetingData?.is_screen_shared == false) {
                setScreenMode('normal')
            }
        }
    }, [meetingData])

    useEffect(() => {
        client.on("user-published", handleUserPublished);
        client.on("user-left", handleUserUnpublished);
    }, [])

    const handleFileChange = async (e) => {
        let file = e.target.files[0];
        setLoading(true)
        if (file) {
            var formData = new FormData();
            const successRes = (res) => {
                setLoading(false)
                let file = res?.data?.data?.[0] ?? ""
                const fileExtension = file.split('.').pop().toLowerCase();
                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension);
                const isPDF = fileExtension === 'pdf';
                sendMessage(file, isImage ? 'image' : isPDF ? 'pdf' : fileExtension);
                e.target.value = null;
            }
            const failureRes = (res) => {
                setLoading(false)
                toast.error("File not upload.Please select another file and try again!")
                return
            }
            formData.append('stream_media', file);
            dispatch(uploadMediaStreaming(formData, 'facilities', successRes, failureRes))
        }
    };

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleVirtualBGSelect}
                accept="image/*"
            />
            {meetingChannel ?
                <div className="common_container">
                    <div className='col-12' style={{ ...myStyles.callHeader, background: isStreamMinimize ? "#ccc" : 'transparent', height: isStreamMinimize ? 50 : "auto" }}>
                        <div style={{ display: isStreamMinimize ? "block" : "none" }}>
                            <div className='row'>
                                <div className='col-3 text-left'>Streaming</div>
                                <div className='col-3 offset-6 text-right'>
                                    <button
                                        style={{ marginRight: 20 }}
                                        onClick={() => dispatch(setIsStreamMinimize(!isStreamMinimize))}
                                        className='common_btn'>
                                        + Maximize
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: isStreamMinimize ? "none" : "block" }}>
                            <div className='row'>
                                <div className='col-3 text-left'>Streaming</div>
                                <div className={(meetingData?.is_screen_shared && meetingData?.shared_by != myUID) ? 'col-6 offset-3 btn-group' : 'col-3 offset-6 btn-group'} style={{ textAlign: "right !important" }}>
                                    {(meetingData?.is_screen_shared && meetingData?.shared_by != myUID) &&
                                        <>
                                            <button
                                                onClick={() => setScreenMode(screenMode ? screenMode == 'full' ? 'normal' : 'full' : 'full')}
                                                className='common_btn px-4'
                                                style={{ width: "45% !important" }}>
                                                {screenMode ? screenMode == 'full' ? 'Exit Full Screen' : 'Full Screen' : 'Full Screen'}
                                            </button> &nbsp; &nbsp;
                                        </>
                                    }
                                    <button
                                        onClick={() => dispatch(setIsStreamMinimize(!isStreamMinimize))}
                                        className='common_btn px-4'>
                                        - Minimize
                                    </button>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-9' ref={mainViewFacility}>
                                    {(meetingData?.is_screen_shared && meetingData?.shared_by != myUID && user_type == "host") &&
                                        <div className='row'>
                                            <div className='col-12 mb-3' style={{ textAlign: "right" }}>
                                                <ul className='m-0 p-0'>
                                                    {recordingLoader ?
                                                        renderRecordingLoader()
                                                        : <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Recording"} onClick={() => handleRecording()} src={renderControls('recording') ? stop_recording : start_recording} alt="recording" style={{ height: '35px', width: '35px', cursor: 'pointer' }} /></li>
                                                    }
                                                    <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Screen Sharing"} onClick={() => handleScreenSharing()} src={renderControls('screen_share') ? stop_sharing_filled : screen_sharing_filled} alt="screen_share" style={{ height: '35px', width: '35px', cursor: 'pointer' }} /></li>
                                                    <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Manage Mic"} onClick={muteUnmute} src={mic ? unmute_filled : mute_filled} alt="mute_unmute" style={{ height: '35px', width: '35px', cursor: 'pointer' }} /></li>
                                                    <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Manage Camera"} onClick={() => manageCamera()} src={camera ? camera_on : camera_off} alt="video_cam" style={{ height: '35px', width: '35px', cursor: 'pointer', background: 'white', padding: '4px', borderRadius: '50%' }} /></li>
                                                    {(meetingData?.host_id == userData?._id && (!meetingData?.is_sos == false)) ?
                                                        <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Add Member"} src={addUser} alt="add_member" style={{ height: '35px', width: '35px' }} onClick={() => setMeetingLinkModal(true)} /></li>
                                                        : null
                                                    }
                                                    <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Upload background"} onClick={() => openFileChooser()} src={virtualBack} alt="virtual_back" style={{ marginTop: '10px', height: '35px', width: '35px', cursor: 'pointer', background: 'white', padding: '4px', borderRadius: '50%' }} /></li>
                                                    <li style={{ display: "inline", marginLeft: '10px' }}><img title={"End Meeting"} src={end_call} alt="end_call" style={{ height: '35px', width: '35px' }} onClick={() => callAborted(userData?._id)} /></li>
                                                </ul>
                                            </div>
                                        </div>}
                                    <div className="row card m-0" id='screenShareRef' style={{ width: '100%', height: '500px', display: (meetingData?.is_screen_shared && meetingData?.shared_by != myUID) ? 'block' : 'none' }} ref={screenShareRef}></div>
                                    <div className="row card m-0" style={{ borderRadius: "5px", overflow: "hidden", height: "500px", display: (meetingData?.is_screen_shared && meetingData?.shared_by != myUID) ? 'none' : 'flex' }}>
                                        {user_type == 'host' &&
                                            <div className="col-3 p-0" style={{ position: "relative" }} >
                                                <div class="row m-0" style={{ position: 'absolute', zIndex: 2, width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                                                    <div class="col-12 p-0">
                                                        <p style={{ fontWeight: 'bold', color: 'cornsilk', textAlign: 'center', fontSize: '20px', letterSpacing: '1px' }}>{renderControls('name') == 0 ? '' : renderControls('name')}</p>
                                                    </div>
                                                    <div class="col-12 p-0" id="host-controls">
                                                        <ul className='m-0 p-0'>
                                                            {recordingLoader ?
                                                                renderRecordingLoader()
                                                                : <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Recording"} onClick={() => handleRecording()} src={renderControls('recording') ? stop_recording : start_recording} alt="recording" style={{ height: '35px', width: '35px', cursor: 'pointer' }} /></li>
                                                            }
                                                            <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Screen Sharing"} onClick={() => handleScreenSharing()} src={renderControls('screen_share') ? stop_sharing_filled : screen_sharing_filled} alt="screen_share" style={{ height: '35px', width: '35px', cursor: 'pointer' }} /></li>
                                                            <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Manage Mic"} onClick={muteUnmute} src={mic ? unmute_filled : mute_filled} alt="mute_unmute" style={{ height: '35px', width: '35px', cursor: 'pointer' }} /></li>
                                                            <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Manage Camera"} onClick={() => manageCamera()} src={camera ? camera_on : camera_off} alt="video_cam" style={{ height: '35px', width: '35px', cursor: 'pointer', background: 'white', padding: '4px', borderRadius: '50%' }} /></li><br />
                                                            {(meetingData?.host_id == userData?._id && (!meetingData?.is_sos)) ?
                                                                <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Add Member"} src={addUser} alt="add_member" style={{ height: '35px', width: '35px', marginTop: '10px' }} onClick={() => setMeetingLinkModal(true)} /></li>
                                                                : null
                                                            }
                                                            <li style={{ display: "inline", marginLeft: '10px' }}><img title={"Upload background"} onClick={() => openFileChooser()} src={virtualBack} alt="virtual_back" style={{ marginTop: '10px', height: '35px', width: '35px', cursor: 'pointer', background: 'white', padding: '4px', borderRadius: '50%' }} /></li>
                                                            <li style={{ display: "inline", marginLeft: '10px' }}><img title={"End Meeting"} src={end_call} alt="end_call" style={{ height: '35px', width: '35px', marginTop: '10px' }} onClick={() => callAborted(userData?._id)} /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='row m-0' style={{ height: "500px" }} ref={mainCaller}></div>
                                            </div>
                                        }
                                        <div className={user_type == "host" ? 'col-9' : 'col-12'}>
                                            <div className="row px-3" id="other_callers"></div>
                                        </div>
                                    </div>
                                    {user_type !== "host" ?
                                        <div className='row mt-3'>
                                            <div className='col-12 text-center'>
                                                <button
                                                    style={{ background: 'none', border: 'none', transform: 'rotate(136deg)', height: '55px' }}
                                                    onClick={() => endByAudience(myUID)}>
                                                    <img style={{ height: '100%' }} src={end_call} />
                                                </button>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className='col-3 card' ref={providerChat}>
                                    <div className='row' style={{ padding: '8px 10px', fontSize: '16px', background: '#3173E0', color: "white", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Live Comments</div>
                                    <div className='row'>
                                        <div className='col' style={{ height: "400px", overflow: "auto" }} ref={messageRef}>
                                            {messages && messages?.map((message) => (
                                                <li className='messageItem my-2' style={{ listStyle: 'none' }}>
                                                    <div className='row'>
                                                        <div className='col-auto pr-0'>
                                                            <img style={{ maxHeight: '25px' }} className='img-round' src={`${message?.msgPic}`} />
                                                        </div>
                                                        <div className='col' style={{ paddingLeft: 0 }}>
                                                            <label className='ml-2'><strong>{message?.msgName}</strong></label>&nbsp;&nbsp;
                                                            {
                                                                message?.message != '' ? (
                                                                    <label>{message?.message}</label>
                                                                )
                                                                    : message?.fileType === 'image' ? (
                                                                        <div>
                                                                            <br />
                                                                            <Image size={30} style={{ zIndex: 99999999 }} shape={'square'} src={fileUrl + message?.file} />
                                                                        </div>

                                                                    ) : message?.fileType === 'mp3' ? (
                                                                        <div>
                                                                            <br />
                                                                            <audio src={fileUrl + message?.file} controls style={{ width: "100%" }}></audio>
                                                                        </div>
                                                                    ) : message?.fileType === 'mp4' ? (
                                                                        <div>
                                                                            <br />
                                                                            <video src={fileUrl + message?.file} controls style={{ width: "100%" }} ></video>
                                                                        </div>
                                                                    ) :
                                                                        (
                                                                            <div>
                                                                                <br />
                                                                                <a href={fileUrl + message?.file} target="_blank">
                                                                                    <img src={pdficon} width="15%" height="auto" title="PDF Viewer" />
                                                                                </a>
                                                                            </div>
                                                                        )
                                                            }
                                                        </div>
                                                        <div className='col-auto pr-0'>
                                                            <small style={{ color: "#888" }}>{moment(message?.msgCreatedAt * 1000).format("HH:mm")}</small>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </div>
                                    </div>
                                    <Spin spinning={loading}>
                                        <div className='row d-flex'>
                                            <div className='col-md-10'>
                                                <Input
                                                    disabled={isText ? true : false}
                                                    style={{ borderRadius: 0 }}
                                                    placeholder="Write here..."
                                                    size="large"
                                                    value={messageText}
                                                    suffix={isText ? <SendOutlined /> : <SendOutlined onClick={() => sendMessage()} />}
                                                    onChange={(e) => setMessageText(e?.target?.value)}
                                                    onKeyDown={(e) => e.key == "Enter" ? sendMessage() : null}
                                                />
                                            </div>
                                            <div className='col-md-2'>

                                                <div className="icon_div d-flex" style={{ cursor: "pointer" }}>
                                                    <input
                                                        type="file"
                                                        name=""
                                                        id="filetype"
                                                        onChange={handleFileChange}
                                                        style={{ width: "20px", position: "absolute", opacity: "0" }}
                                                        accept=".png, .jpg, .jpeg, .pdf, .mp4, .mp3"
                                                    />
                                                    <div className='d-flex align-items-center'>
                                                        <UploadOutlined style={{ fontSize: "30px" }} className="me-2" color="104861" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
            <Modal
                visible={meetingLinkModal}
                footer={null}
                maskClosable={false}
                title={"Invite a New Members"}
                onCancel={() => setMeetingLinkModal(false)}>
                <div className='row'>
                    <div className='form-group mb-3'>
                        <label><strong>Join as a Host</strong></label><br />
                        <Input
                            readOnly={true}
                            value={adminUrl + "/#/meeting/821" + meetingInviteLink ?? ''}
                            suffix={
                                <CopyOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCopyClick(adminUrl + "/#/meeting/821" + meetingInviteLink ?? '')}
                                />
                            }
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label><strong>Join as a Audience</strong></label><br />
                        <Input
                            readOnly={true}
                            value={meetingData?.type == "Public" ? (webUrl + "/#/381" + meetingInviteLink) : (adminUrl + "/#/meeting/381" + meetingInviteLink)}
                            suffix={
                                <CopyOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCopyClick(meetingData?.type == "Public" ? (webUrl + "/#/381" + meetingInviteLink) : (adminUrl + "/#/meeting/381" + meetingInviteLink))}
                                />
                            }
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12' style={{ textAlign: "right" }}>
                        <button disabled={btnLoading} onClick={() => setMeetingLinkModal(false)} className='btn btn-primary btn-sm'>Close</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const myStyles = {
    callHeader: {
        borderRadius: "5px",
        marginTop: "28px",
        padding: "5px 10px",
        lineHeight: "37px",
        boxShadow: "2px 2px 5px #ccc"
    }
}
export default Streaming