import axios from "axios";
import { baseUrl } from "../Constant/url";
const userID = localStorage.getItem("myId")
export const apiCall = (config, dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: config?.method,
            url: config?.url,
            headers: config?.headers,
            data: config?.data || {},
            timeout: 70000,
        })
            .then(async (response) => {
                resolve(response)
            })
            .catch(async (error) => {
                let response = error?.response;
                if (response?.status === 401) {
                    localStorage.clear()
                    sessionStorage.clear()  
                    // document.location.href= window.location.origin                       
                } else if (response?.status === 451 || response?.status === 429) {
                    // localStorage.clear()
                    // sessionStorage.clear()
                }
                reject(error);
            });
    });
};

export const xhrFormDataApiCall = (config, dispatch) => {
    console.log(config)
    return new Promise(async (resolve, reject) => {
        let respData = {}
        let url = config.url;
        let xhr = new XMLHttpRequest();
        let formData = !config.data ? '' : config.data
        formData = await getFormData(formData)
        xhr.open(config.method, url);
        if (config?.headers?.Authorization) {
            xhr.setRequestHeader('Authorization', config?.headers?.Authorization)
        }
        xhr.send(formData != '' ? formData : '');
        xhr.onload = function () {
            let obj = JSON.parse(xhr.responseText);
            if (xhr.status === 200 || xhr.status === 403) {
                let status = obj.status;
                let message = obj.message;
                if (!status) {
                    respData.status = false
                    respData.message = message
                    return resolve(respData)
                }
                respData.status = true
                respData.message = message
                respData.data = obj.data
                return resolve(respData)

            } else {
                console.log('XHR API ISSUE', obj.message);
            }
        };
    })
};

async function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        if (typeof object[key] !== 'object') {
            formData.append(key, object[key])
        } else {
            formData.append(key, object[key])
        }
    })
    return formData;
}

const refreshToken = (config, dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${baseUrl}users/refresh`,
            data: {
                _id: userID,
                type: 'user'
            }
        })
            .then(async (response) => {
                resolve(response);
                console.log("respresponse", response)
                window.location.reload();
            })
            .catch(async (error) => {
                console.log(error)
            });
    });
};
