import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Tag, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { facilityHospitalDetail, providerRoles } from '../../../redux/action/provider/auth';
import { facilityHospital } from '../../../redux/action/facility/patient';
import { listSelfEmissionWizardMenu, getDoctorsList, addSelfEmissionWizard, getSitterList, medicationSugg } from '../../../redux/action/patient/form';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css'
import { FACILITY_ID } from '../../../Constant/creds';
import moment from 'moment';

const { Option } = Select;

const SelfEmissionWizardAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState([]);
    const [prnMedication, setPrnMedication] = useState("");
    const [loading, setLoading] = useState(false);
    const [hospitalList, setHospitalList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [sitterList, setSitterList] = useState([]);
    const [dateVal, setDateVal] = useState([]);
    const [admissionDate, setAdmissionDate] = useState([]);
    const [dateValDis, setDateValDis] = useState([]);
    const [medSugg, setMedSugg] = useState([]);
    const [menuTags, setMenuTags] = useState([]);
    const [selectedMenuIds, setSelectedMenuIds] = useState([]);
    const [dropDownVal, setDropDownVal] = useState({
        facility_id: '',
        provider_department: '',
        role: '',
        hospital: ''
    });

    // For Required Fields Start
    const initialState = {
        profile: { border: "" },
        first_name: { border: "" },
        last_name: { border: "" },
        email: { border: "" },
        dob: "",
        location: { border: "" },
        city: { border: "" },
        state: { border: "" },
        country: { border: "" },
        emergency_name: { border: "" },
        emergency_num: { border: "" },
        emergency_email: { border: "" },
        id_number: { border: "" },
        ssn: { border: "" },
        phone_num: { border: "" },
        state_license_num: { border: "" },
        state_license_file: { border: "" },
        role: { border: "" },
        hospital: { border: "" },
    };
    // For Required Fields End

    const userData = useSelector(state => state?.auth?.get_user);
    const rolesDetail = useSelector(state => state?.auth?.provider_roles?.data);
    const facilityData = useSelector((state) => state?.auth?.get_user);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isSuicidal, setIsSuicidal] = useState(false);

    const handleDropDownVal = (e) => {
        e.target.name === "suicidal" && e.target.value === 'Yes' ? setIsSuicidal(true) : setIsSuicidal(false);
        if (e.target.name === "role") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const id = selectedOption.getAttribute("id");
            getDoctors(id);
        }
        setDropDownVal((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
            [`${e.target.name}Text`]: e.target.options[e.target.selectedIndex].text
        }))
    }

    const getDoctors = (role) => {
        const success = (res) => {
            setDoctorList(res?.data)
        }
        const failure = (res) => {
            setDoctorList([])
        }
        let data = {
            facility_id: FACILITY_ID,
            role,
            page: 1,
            limit: 100,
            type: "all"
        }

        dispatch(getDoctorsList(data, success, failure))
    }

    const getSitters = (role) => {
        const success = (res) => {
            setSitterList(res?.data)
        }
        const failure = (res) => {
            setSitterList([])
        }
        let data = {
            facility_id: FACILITY_ID,
            provider_department: "Sitter",
            page: 1,
            limit: 100,
            type: "all"
        }

        dispatch(getSitterList(data, success, failure))
    }

    const handleInputChange = (newTags, t = '') => {
        if (t === '') {
            setInputVal(newTags);
        } else {
            // Ensure the clicked tag is not a duplicate
            if (!newTags.includes(t)) {
                const updatedTags = [...newTags, t];
                setInputVal(updatedTags);
            }
        }
    };

    const handleMenuTags = (tags) => {
        // Extract _id and text separately from the selected options
        const ids = tags;
        const texts = tags.map(tag => menuList.find(element => element._id === tag)?.text || '');

        // Update state with the selected _ids and texts
        setSelectedMenuIds(ids);
        setMenuTags(texts);
    };

    const getMedicationSugg = (medication_name) => {
        const success = (res) => {
            setMedSugg(res?.data)
        }
        const failure = () => {
            setMedSugg([])
        }
        let data = {
            medication_name
        }
        dispatch(medicationSugg(data, success, failure))
    }


    const handleInputChangePrn = (e) => {
        setPrnMedication(e.target.value)
    }

    const getActiveHospital = () => {
        const success = (res) => {
            setHospitalList(res?.data?.data)
        }
        const failure = (res) => {
            console.log(res, "failure")
        }
        dispatch(facilityHospital(success, failure))
    }

    const getMenuList = () => {
        const success = (res) => {
            setMenuList(res?.data)           
        }
        const failure = (res) => {
            setMenuList([])
        }
        dispatch(listSelfEmissionWizardMenu(success, failure))
    }

    useEffect(() => {
        let data = {
            page: 1,
            limit: 100
        }
        dispatch(facilityHospitalDetail(data))
        dispatch(providerRoles())
        getActiveHospital()
        getMenuList()
        getSitters()
        disabledDate();
        disabledDateDis();
    }, [])

    const handleCreate = () => {
        const errors = {};
        if (!dropDownVal?.suicidal) {
            errors.suicidal = 'suicidal';
        }
        if (!dropDownVal?.hospital) {
            errors.hospital = 'hospital';
        }
        if (!dropDownVal?.medical_transportation) {
            errors.medical_transportation = 'Transport to come';
        }
        if (!dropDownVal?.whendischarged) {
            errors.whendischarged = 'When Discharged Were to go';
        }
        if (!dropDownVal?.transport_back_home) {
            errors.transport_back_home = 'Transport back home';
        }
        if (menuTags.length == 0) {
            errors.menu = 'Menu';
        }
        if (!dropDownVal.role) {
            errors.role = 'role';
        }
        if (!dropDownVal.doctor) {
            errors.doctor = 'doctor';
        }
        if (!dropDownVal.sitter) {
            errors.sitter = 'sitter';
        }

        const errorFields = Object.keys(errors);
        if (errorFields.length > 0) {
            let errorMessage = '';
            errorFields.forEach(field => {
                if (errorMessage) {
                    errorMessage += ', ';
                }
                errorMessage += errors[field];
            });
            errorMessage = `Please select an option for ${errorMessage}`;
            toast.error(errorMessage);
            return;
        }

        setLoading(true)
        const successRes = (res) => {
            setLoading(false)
            toast.success(res?.message)
            navigate('/self_emission_wizard')
        }
        const failureRes = (res) => {
            setLoading(false)
            toast.error(res?.message)
        }
        let data = {
            patient_id: userData?._id,
            facility_id: FACILITY_ID,
            suicidal_call_911: dropDownVal?.suicidal,
            hospital_id: dropDownVal?.hospital,
            admission_date: dateVal || "",
            discharge_date: dateValDis || "",
            transport_to_come: dropDownVal?.medical_transportation,
            when_discharged_were_to_go: dropDownVal?.whendischarged,
            transport_back_home: dropDownVal?.transport_back_home,
            menu: selectedMenuIds,
            menu_name: menuTags,
            medication: inputVal,
            prn_medication: prnMedication,
            role_id: dropDownVal?.role,
            provider_id: dropDownVal?.doctor,
            select_sitter_id: dropDownVal?.sitter,
            hospital_name: dropDownVal?.hospitalText,
            role_name: dropDownVal?.roleText,
            provider_name: dropDownVal?.doctorText,
            sitter_name: dropDownVal?.sitterText,
        }
        dispatch(addSelfEmissionWizard(data, successRes, failureRes))
    }

    useEffect(() => {
        if (facilityData?.user_type == 'super') {
            setIsSuperAdmin(true)
        } else {
            facilityData?.access?.forEach((itm) => {
                if (itm?.menu_name == 'Patients') {
                    setReadWritePermit(itm)
                }
            })
            setIsSuperAdmin(false)
        }
    }, [isSuperAdmin, facilityData, readWritePermit?.value?.write]);

    const handleDateChange = (date, dateString) => {
        setAdmissionDate(date)
        setDateVal(dateString)
    }

    const handleDateChangeDischage = (date, dateString) => {
        setDateValDis(dateString)
    }

    // Define disabledDate function
    function disabledDate(current) {
        // Disable past dates
        return current && current < moment().startOf('day');
    }

    function disabledDateDis(current) {
        
        // Disable past dates / by admission date selection
        return current && current < moment().startOf('day') || current < admissionDate;
    }


    return (
        <>
            <div className="common_content">
                <div className="container-fluid common_container">
                    <div className="row">
                        <div className="">
                            <div className="profile_card col-lg-6 col-xxl-5 mx-auto">
                                <h1 className='large_font pb-3'>Add Self Emission Wizard</h1>
                                <div className="row g-4 d-flex align-items-center">
                                    <div className="col-md-12">
                                        <div className=" extra_padding" style={{ height: "100%" }}>
                                            <div className="row gy-3">
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Suicidal</label>
                                                    <select className='input_fields px-2 selectFont' name='suicidal' onChange={handleDropDownVal}>
                                                        <option value="">Please Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                    {isSuicidal ?
                                                        <>Call 911 </>
                                                        : ""}
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'> Which Hospital   </label>
                                                    <select className='input_fields px-2 selectFont' name='hospital' value={dropDownVal.hospital} onChange={handleDropDownVal}>
                                                        <option value="">Select Hospital</option>
                                                        {hospitalList && hospitalList?.map((item, indx) => (
                                                            <option value={item?._id} key={indx}>{item?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Admission Date</label>
                                                    <DatePicker onChange={(date, string) => handleDateChange(date, string)}
                                                        className='input_fields'
                                                        placeholder='Select date'
                                                        disabledDate={disabledDate}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Discharge Date</label>
                                                    <DatePicker 
                                                        onChange={(date, string) => handleDateChangeDischage(date, string)}
                                                        className='input_fields'
                                                        placeholder='Select date'
                                                        disabledDate={disabledDateDis}
                                                    />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Transport to come</label>
                                                    <select className='input_fields px-2 selectFont' name='medical_transportation' onChange={handleDropDownVal}>
                                                        <option value="">Please Select</option>
                                                        <option value="Medical transportation">Medical transportation</option>
                                                        <option value="Bus pass">Bus pass</option>
                                                        <option value="Ambulance">Ambulance</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>When Discharged Were to go</label>
                                                    <select className='input_fields px-2 selectFont' name='whendischarged' onChange={handleDropDownVal}>
                                                        <option value="">Please Select</option>
                                                        <option value="Group home">Group home</option>
                                                        <option value="Shelter">Shelter</option>
                                                        <option value="Nursing home">Nursing home</option>
                                                        <option value="Cila home">Cila home</option>
                                                        <option value="Friend house">Friend house</option>
                                                        <option value="Family house">Family house</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Transport back home</label>
                                                    <select className='input_fields px-2 selectFont' name='transport_back_home' onChange={handleDropDownVal}>
                                                        <option value="">Please Select</option>
                                                        <option value="Medical transportation">Medical transportation</option>
                                                        <option value="Bus pass">Bus pass</option>
                                                        <option value="Ambulance">Ambulance</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Menu</label>
                                                    <Select
                                                        mode="multiple"
                                                        onChange={(newTags) => { handleMenuTags(newTags) }}
                                                        style={{ width: '100%' }}
                                                        placeholder="Add menu"
                                                        className='medselector'
                                                    >
                                                        {menuList && menuList?.map((element, index) => (
                                                            <Option key={index} value={element._id}>
                                                                {element.text}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>

                                                <div className="col-lg-12">
                                                    <label className='small_font'>Medication</label>
                                                    <Select
                                                        mode="tags"
                                                        value={inputVal}
                                                        onSearch={(newTags) => { getMedicationSugg(newTags) }}
                                                        onChange={(newTags) => { handleInputChange(newTags) }}
                                                        style={{ width: '100%' }}
                                                        placeholder="Add medications"
                                                        className='medselector'
                                                    >
                                                        {medSugg && medSugg?.map((element, index) => (
                                                            <Option key={index} value={element}>
                                                                {element}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>PRN Medication</label>
                                                    <input
                                                        className='input_fields'
                                                        name="prn_medication"
                                                        onChange={handleInputChangePrn}
                                                    />
                                                </div>

                                                <div className="col-lg-12">
                                                    <label className='small_font'>Choose Program</label>
                                                    <select className='input_fields px-2' name='role' onChange={handleDropDownVal}>
                                                        <option value="">Select Roles:-</option>
                                                        {rolesDetail && rolesDetail?.map((item, index) => (
                                                            <option key={index} id={item?.role} value={item?._id}>{item?.role}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-lg-12">
                                                    <label className='small_font'>Doctor</label>
                                                    <select className='input_fields px-2 selectFont' name='doctor' onChange={handleDropDownVal}>
                                                        <option value="">Please Select</option>
                                                        {doctorList && doctorList?.map((item, index) => (
                                                            <option key={index} value={item?._id}>{item?.first_name + " " + item?.last_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Sitter</label>
                                                    <select className='input_fields px-2 selectFont' name='sitter' onChange={handleDropDownVal}>
                                                        <option value="">Please Select</option>
                                                        {sitterList && sitterList?.map((item, index) => (
                                                            <option key={index} value={item?._id}>{item?.first_name + " " + item?.last_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="w-50 mx-auto">
                                                    <Button type='ghost' onClick={() => handleCreate()} className="common_btn mt-3">Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SelfEmissionWizardAdd;
