import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
import { FACILITY_HOSPITAL_DETAILS, PROVIDER_DEPARTMENT_DETAILS, PROVIDER_ROLES_DETAILS } from "../../type";

// Provider main login auth
export const providerLogin = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/login`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const verifyProviderEmail = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/send_verification_email`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Google Authenticator
export const enableProviderAuth = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/enable_2fa`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

export const verifyProviderAuth = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/verify_2fa`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Provider logout
export const providerLogout = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}providers/logout`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Provider Signup Facility Hospital Detail
export const facilityHospitalDetail = (data) => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}facilities/all_facilities`,
        headers: { 'content-type': 'application/json' },
        data
    }
    const resp = await apiCall(config, dispatch);
    try {
        if (resp?.data?.status) {
            dispatch({
                type: FACILITY_HOSPITAL_DETAILS,
                payload: resp?.data
            })
        }
    } catch (error) {
        failureRes(error)
    }
}

// Provider Department Detail
export const providerDepartment = () => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}providers/get_provider_departments`,
        headers: { 'content-type': 'application/json' }
    }
    const resp = await apiCall(config, dispatch);
    try {
        if (resp?.data?.status) {
            dispatch({
                type: PROVIDER_DEPARTMENT_DETAILS,
                payload: resp?.data
            })
        }
    } catch (error) {
        failureRes(error)
    }
}

// Provider Roles Detail
export const providerRoles = () => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}providers/get_provider_roles`,
        headers: { 'content-type': 'application/json' }
    }
    const resp = await apiCall(config, dispatch);
    try {
        if (resp?.data?.status) {
            dispatch({
                type: PROVIDER_ROLES_DETAILS,
                payload: resp?.data
            })
        }
    } catch (error) {
        failureRes(error)
    }
}

// Provider Register
export const providerRegister = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        url: `${baseUrl}providers/register`,
        headers: { 'content-type': 'application/json' },
        data
    }
    const resp = await apiCall(config, dispatch);
    console.log("stafRes",resp);
    try {
        if (resp?.data?.status) {
           successRes(resp?.data)
        }else{
            failureRes(resp?.data)
        }
    } catch (error) {
        failureRes(error)
    }
}