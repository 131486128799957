import React, { useEffect, useState, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgProfile } from 'react-icons/cg';
import { RiLockPasswordLine } from 'react-icons/ri';
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Drawer, Menu, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fileUrl } from '../Constant/url';
import { toast } from 'react-toastify';
import { providerLogout } from '../redux/action/provider/auth';
import { facilityLogout } from '../redux/action/facility/auth';
import { patientLogout } from '../redux/action/patient/auth';
import { familyLogout } from '../redux/action/family/auth';
import { facilityProfile, updateFacilityProfile } from '../redux/action/facility/profile';
import { providerProfile, updateProviderProfile } from '../redux/action/provider/profile';
import { patientProfile } from '../redux/action/patient/profile';
import { familyProfile } from '../redux/action/family/profile';
import { GET_USER } from "../redux/type";
import { collection, addDoc, getDocs, updateDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { checkUserExist } from '../redux/action/common';
import { getSuperAdminApi } from '../redux/action/super/auth';
import { hospitalLogout } from '../redux/action/hospital/auth';
import { hospitalProfile } from '../redux/action/hospital/profile';
import { setMeetingChannel, setMeetingToken, createAgoraToken, setUserType, addSOSRecord } from '../redux/action/facility/meeting';
import { getMyFamilyMembers, getMyTreatmentMembers } from '../redux/action/family/meeting';
import User from '../assets/user.png';
import Logo from '../assets/logo.png';
import sos from '../assets/icons/sos.png';
import Toaster from '../Constant/Toaster';
import ChatCalling from './ChatCalling';
import GoLive from './GoLive';
import Streaming from './Streaming';
import Swal from 'sweetalert2';
import Md5 from 'md5';
import moment from 'moment';
import axios from 'axios';

const LecinseAlert = ({ alert, expiry }) => (
    <div style={{ backgroundColor: "#ed281a", color: "White", height: "26px", textAlign: "CENTER", fontSize: "14px", paddingTop: "3px" }}>
        YOUR LICENSE IS EXPIRING WITH IN {expiry} DAYS, RENEW IT NOW <span onClick={alert} style={{ float: "right", marginRight: "10px", cursor: "pointer" }}>X</span>
    </div>
)

const Header = () => {
    const ref = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const admin_token = localStorage.getItem('admin_token');
    const provider_token = localStorage.getItem('provider_token');
    const facility_token = localStorage.getItem('facility_token');
    const family_token = localStorage.getItem('family_token');
    const patient_token = localStorage.getItem('patient_token');
    const hospital_token = localStorage.getItem('hospital_token');
    const provider_user = localStorage.getItem("provider_user");
    const super_token = localStorage.getItem("super_token");
    const deviceUUID = localStorage.getItem('uuid');
    const [openSide, setOpenSide] = useState(false);
    const [placement, setPlacement] = useState('top');
    const [headerMenu, setHeaderMenu] = useState([]);
    const [activeIndex, setActiveIndex] = useState('/dashboard');
    const [selectedStatusValue, setSelectedStatusValue] = useState('');
    const contract_expiry = provider_user ? JSON.parse(provider_user)?.data?.contract_expiry : 11;
    const [licenseAlert, setLicenseAlert] = useState(contract_expiry);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [navigateUrl, setNavigateUrl] = useState(null);
    const [hosts, setHosts] = useState([])
    const [SOSCallModal, setSOSCallModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [SOSChannelName, setSOSChannelName] = useState('');
    const [currentLocation, setCurrentLocation] = useState(null);
    const [isSOS, setIsSOS] = useState(false);
    ////
    const userData = useSelector((state) => state?.auth?.get_user);
    const isUserExist = useSelector((state) => state?.common?.is_user_exist);
    const headerMenuu = useSelector((state) => state?.auth?.get_menu?.data);
    const contractStatus = useSelector((state) => state?.header?.is_contract_expired);
    const isLive = useSelector((state) => state.header.is_live)
    ////
    let providerType = userData?.provider_department;
    let isSupervisor = userData?.is_supervisor;
    let userProfilePic = userData?.profile_pic;
    let facilityProfilePic = userData?.image;

    const showDrawer = () => {
        setOpenSide(true)
    }

    const handlingPrivilegeAccess = (userDataa, headerMenuu, accessData) => {
        if (userDataa?.user_type == "privileged" && headerMenuu) {
            const filteredItems = [];
            headerMenuu.forEach((item) => {
                if (accessData.some((accessItem) => accessItem?.menu_name === item?.name)) {
                    filteredItems.push(item);
                }
            });
            if (filteredItems?.[0]?.name == "Receptionist") {
                setNavigateUrl(filteredItems?.[1]?.url)
            } else {
                setNavigateUrl(filteredItems?.[0]?.url)
            }

            setHeaderMenu(filteredItems)
        } 
        else {
            // const newMenuItem = {
            //     "url": "/activityLogs",
            //     "name": "Activity Logs",
            //     "access_url": ["/activityLogs"],
            // };

            setHeaderMenu(headerMenuu);
            setNavigateUrl('/dashboard')
        }
        /* Check For "Receptionist" */
        if (userDataa?.user_type == 'super') {
            setIsSuperAdmin(true)
        } else {
            userDataa?.access?.forEach((itm) => {
                if (itm?.menu_name == 'Receptionist') {
                    setReadWritePermit(itm)
                }
            })
            setIsSuperAdmin(false)
        }
    }

    /* Getting Facility Detail */
    useEffect(() => {
        if (facility_token) {
            let userId = JSON.parse(localStorage.getItem('facility_user'))
            const myObj = {
                id: userId?.other?._id
            }
            const successCb = (response) => {
                handlingPrivilegeAccess(response?.data?.data, headerMenuu, response?.data?.data?.access)
                dispatch({ type: GET_USER, payload: response?.data?.data });
            }
            const failureCb = (response) => {
                Swal.fire({
                    icon: 'error',
                    title: `Admin has deleted your Account`,
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    handleFacLog()
                }, 1500);
            }
            dispatch(facilityProfile(myObj, successCb, failureCb))
        } else {
            setHeaderMenu(headerMenuu)
        }
    }, [headerMenuu, facility_token, isUserExist])

    /* Logic to check user Existance on header Click */
    const handleCheckUser = (name) => {
        if (facility_token) {
            dispatch(checkUserExist(name))
        }
    }

    // provider logout
    const handleProviderLogout = () => {
        const successRes = (res) => {
            update_firebase_activeField('offline')
            toast.success(res?.data?.message)
            localStorage.clear()
            sessionStorage.clear()
            redirect();
        }
        const failureRes = (res) => {
            toast.error(res?.data?.message)
        }
        let data = {
            provider_id: userData?._id,
            device_id: deviceUUID
        }
        dispatch(providerLogout(data, successRes, failureRes))
    }

    // facility logout
    const handleFacLog = () => {
        const successRes = (res) => {
            if (isLive) {
                Swal.fire({
                    title: 'Confirmation',
                    text: 'You are currently in a video call. Are you sure you want to log out?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        update_firebase_activeField('offline')
                        toast.success(res?.data?.message)
                        localStorage.clear()
                        sessionStorage.clear()
                        redirect();
                    }
                });
            }
            else {
                update_firebase_activeField('offline')
                toast.success(res?.data?.message)
                localStorage.clear()
                sessionStorage.clear()
                redirect();
            }
        }
        const failureRes = (res) => {
            toast.error(res?.data?.message)
        }
        let data = {
            facility_id: userData?._id,
            device_id: deviceUUID
        }
        dispatch(facilityLogout(data, successRes, failureRes))
    }

    const handleFacilityLogout = () => {
        handleFacLog()
    }

    // patient logout
    const handlePatientLogout = () => {
        const successRes = (res) => {
            update_firebase_activeField('offline')
            toast.success(res?.data?.message)
            localStorage.clear()
            sessionStorage.clear()
            redirect();
        }
        const failureRes = (res) => {
            toast.error(res?.data?.message)
        }
        let data = {
            patient_id: userData?._id,
            device_id: deviceUUID
        }
        dispatch(patientLogout(data, successRes, failureRes))
    };

    // family logout
    const handleFamilyLogout = () => {
        const successRes = (res) => {
            update_firebase_activeField('offline')
            toast.success(res?.data?.message)
            localStorage.clear()
            sessionStorage.clear()
            redirect();
        }
        const failureRes = (res) => {
            toast.error(res?.data?.message)
        }
        let data = {
            family_member_id: userData?._id,
            device_id: deviceUUID
        }
        dispatch(familyLogout(data, successRes, failureRes))
    }

    // Super Admin Logout
    const handleSuperAdminLogout = () => {
        const successCb = (res) => {
            update_firebase_activeField('offline')
            toast.success(res?.data?.message)
            localStorage.clear()
            sessionStorage.clear()
            redirect();
        }
        const failureCb = (res) => {
            toast.error(res?.data?.message)
        }
        let myObj = {
            admin_id: userData?._id,
            device_id: deviceUUID
        };
        const url = `administration/logout`
        dispatch(getSuperAdminApi(url, myObj, successCb, failureCb))
    }

    // Hospital Logout
    const handleHospitalLogout = () => {
        const successCb = (res) => {
            update_firebase_activeField('offline')
            toast.success(res?.data?.message)
            localStorage.clear()
            sessionStorage.clear()
            navigate('/')
        }
        const failureCb = (res) => {
            toast.error(res?.data?.message)
        }
        let myObj = {
            hospital_id: userData?._id,
            device_id: deviceUUID
        };
        dispatch(hospitalLogout(myObj, successCb, failureCb))
    }

    useEffect(() => {
        setActiveIndex(location.pathname)
    }, [activeIndex, { ...location }])

    let loggedInType = localStorage.getItem('login_type')

    useEffect(() => {
        const success = (resp) => {
            if (loggedInType == 'Admin') {
                dispatch({ type: GET_USER, payload: resp?.data });
                update_firebase_activeField('online')
                setSelectedStatusValue(resp?.data?.chat_status);
            } else {
                dispatch({ type: GET_USER, payload: resp?.data?.data });
                update_firebase_activeField('online')
                setSelectedStatusValue(resp?.data?.data?.chat_status)
            }
        }
        const failure = (resp) => {
            console.log('failure', resp)
        }

        if (facility_token) {
            let userId = JSON.parse(localStorage.getItem('facility_user'))
            const myObj = {
                id: userId?.other?._id
            }
            dispatch(facilityProfile(myObj, success, failure))
        } else if (provider_token) {
            dispatch(providerProfile(success, failure))
        } else if (patient_token) {
            dispatch(patientProfile(success, failure))
        } else if (family_token) {
            dispatch(familyProfile(success, failure))
        } else if (super_token) {
            const myObj = null
            const url = `administration/get`
            dispatch(getSuperAdminApi(url, myObj, success, failure))
        } else if (hospital_token) {
            dispatch(hospitalProfile(success, failure))
        }
        else {
            localStorage.clear()
            sessionStorage.clear()
            navigate("/")
        }
    }, [userData != null])

    const changeOnlineStatus = (event) => {
        const sucess = (resp) => {
            update_firebase_activeField(event.target.value)
        }
        const failure = (resp) => {
            console.log(resp)
        }
        let data = {
            chat_status: event.target.value,
            id: userData?._id
        };
        if (admin_token) {

        } else if (facility_token) {
            dispatch(updateFacilityProfile(data, sucess, failure))
        } else if (provider_token) {
            dispatch(updateProviderProfile(data, sucess, failure))
        } else if (patient_token) {
            //dispatch(patientProfile(sucess, failure))
        } else if (family_token) {
            // dispatch(familyProfile(sucess, failure))
        } else {
            /////
        }
        setSelectedStatusValue(event.target.value);
    };

    const update_firebase_activeField = (type) => {
        const dataCol = collection(db, "colony-chat");
        getDocs(dataCol).then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((document) => {
                    let profile = document.data().profile;
                    let idx = profile.findIndex(x => x._id === userData?._id);
                    if (idx !== -1) {
                        profile[idx].chat_status = type;
                    }
                    updateDoc(doc(db, "colony-chat", document.id), {
                        profile
                    }).then(() => {
                        // console.log("Document successfully updated!");
                    }).catch((error) => {
                        console.error("Error updating document: ", error);
                    });
                });
            } else {
                console.log("No documents found matching the conditions.");
            }
        }).catch((error) => {
            console.error("Error getting documents: ", error);
        });
    }

    /* For Facility Navigating Privileged user according to access array */
    const [matchedArray, setMatchedArray] = useState([]);

    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        var { latitude, longitude } = position.coords;
                        var apiKey = process.env.REACT_APP_GOOGLE_API_KEY
                        var apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
                        axios.get(apiUrl).then((response) => {
                            const data = response.data
                            if (data.results && data.results.length > 0) {
                                var location = data.results[0].formatted_address;
                                return resolve(location)
                            } else {
                                return resolve(null)
                            }
                        }).catch((err) => {
                            console.error('Error getting address: ' + err);
                            return resolve(null)
                        })
                    }, (error) => {
                        console.error('Error getting location: ' + error.message);
                        return resolve(null)
                    });
                } else {
                    console.error('Geolocation is not supported by this browser.');
                    return resolve(null)
                }
            } catch (err) {
                console.error(err, 'Geolocation is not supported by this browser.');
                return resolve(null)
            }
        })
    }

    const sosCall = async () => {
        if (isSOS) {
            toast.error("Sorry!! You have already connected a sos call. Please end the existing call to join a new call.")
            return;
        }
        else {
            try {
                const loc = await getCurrentLocation();
                console.log("current location", loc)
                setCurrentLocation(loc)
                var stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                stream.getTracks().forEach(track => track.stop());
                setSOSChannelName(`colony_${Math.floor(Math.random() * 1000000)}`)
                setSOSCallModal(true)
            } catch (err) {
                toast.error("No Camera or Mic Access")
                let sosData = {
                    patient_id: userData?._id,
                    connect_status: "No",
                    payment_status: "No Camera or Mic Access"
                }
                dispatch(addSOSRecord(sosData))
            }
        }
    }

    const initialiseSOSCall = async () => {
        try {
            setBtnLoading(true)
            let channel_name = SOSChannelName
            let data = {
                channel_name
            }
            let hostsList = []
            let callBack = async (response) => {
                if (response?.status) {
                    setIsSOS(true);
                    await setDoc(doc(db, "meeting", channel_name), {
                        meeting_date: moment().unix(),
                        meeting_thumb: "facility_media/facility_media-1701424466123.webp",
                        user_id: userData?._id,
                        topic: `SOS ${userData?.first_name} - ${userData?.patient_account}`,
                        description: `${userData?.first_name} needs help`,
                        type: 'Private',
                        invites: hostsList?.map((hl) => (hl?.user_id)),
                        audience_invites: [],
                        host_id: userData?._id,
                        is_live: 1,
                        is_screen_shared: false,
                        meeting_channel: channel_name,
                        invite_link: null,
                        is_sos: true,
                        patient_location: currentLocation
                    })
                    const callBackUserType = () => {
                        let sosData = {
                            sos_channel_name: SOSChannelName,
                            patient_id: userData?._id,
                            connect_status: "Yes",
                            payment_status: "Pending",
                            patient_location: currentLocation
                        }
                        dispatch(addSOSRecord(sosData))
                        dispatch(setMeetingChannel(channel_name))
                        dispatch(setMeetingToken(response?.data ?? null))
                        setBtnLoading(false)
                        setSOSCallModal(false)
                        // Send Notications
                        hostsList?.map((hs) => {
                            addDoc(collection(db, "notifications"), {
                                for_id: hs?.user_id,
                                ref: hs?.user_type,
                                is_read: false,
                                title: "SOS Call Started",
                                body: `Your Patient ${userData?.first_name} (${userData?.patient_account}) has started a SOS Call, Please Respond`,
                                type: 'sos_call',
                                type_ref: null,
                                name: `${userData?.first_name} (${userData?.patient_account})`,
                                created_on: moment().unix()
                            }).then((docRef) => {
                                const doc_id = docRef.id;
                                return updateDoc(docRef, { doc_id });
                            }).then(() => {
                                console.log("Document created and updated successfully!");
                            }).catch((error) => {
                                console.log("Error creating document: ", error);
                            });
                        })
                    }
                    dispatch(setUserType('host', callBackUserType))
                } else {
                    setBtnLoading(false)
                    toast.error(response?.message)
                }
            }
            let FMCallBack = (FMResponse) => {
                if (FMResponse?.status) {
                    FMResponse?.data?.map((sm) => {
                        hostsList.push({ user_type: "Family Member", user_id: sm?._id })
                    })
                }
                setHosts(hostsList)
                dispatch(createAgoraToken(data, callBack))
            }
            let TMCallBack = (TMResponse) => {
                if (TMResponse?.status) {
                    if (TMResponse?.data?.rendering_provider) {
                        hostsList.push({ user_type: "Provider", user_id: TMResponse?.data?.rendering_provider?._id })
                    }
                }
                dispatch(getMyFamilyMembers(FMCallBack))
            }
            dispatch(getMyTreatmentMembers(TMCallBack))
        } catch (error) {
            console.log(error)
            setBtnLoading(false)
            toast.error("Error while making SOS Call")
            return false;
        }
    }

    useEffect(() => {
        if (userData?.user_type == "privileged") {
            if (userData?.access.length > 0) {
                const findMatches = () => {
                    const newArray = [];
                    userData?.access?.forEach((item1) => {
                        headerMenuu?.forEach((item2) => {
                            if (item1?.menu_name === item2?.name) {
                                newArray.push({
                                    name: item2?.name,
                                    url: item2?.url,
                                    access_url: item2?.access_url,
                                });
                            }
                        });
                    });
                    setMatchedArray(newArray);
                };
                findMatches();
            }
            else {
                navigate('NoPermit');
            }
        }
    }, [userData, location?.pathname]);

    /* Responsible for Navigation */
    useEffect(() => {
        // Check if the manually entered URL matches any URL in matchedArray
        const manuallyEnteredUrl = location?.pathname;

        if (userData?.user_type == "privileged") {
            if (userData?.access?.length > 0) {
                const hasAccessToURL = hasAccess(manuallyEnteredUrl);
                if (!hasAccessToURL) {
                    if (matchedArray[0]?.url !== '#' || matchedArray[0]?.url != '/facility-profile') {
                        navigate(matchedArray[0]?.url);
                    } else {
                        navigate(matchedArray[1]?.url);
                    }
                    // Navigate the user to the first item in matchedArray if not matching and not #
                }
            } else {
                navigate('NoPermit');
            }
        }
        else {
            if (manuallyEnteredUrl === '/NoPermit') {
                navigate('dashboard');
            }
        }
        return;

    }, [location.pathname, matchedArray]);

    function hasAccess(manuallyEnteredUrl) {
        for (const item of matchedArray) {
            if (item?.access_url?.includes(manuallyEnteredUrl)) {
                return true;
            }
        }
        return false;
    }

    const redirect = () => {
        setTimeout(() => {
            document.location.href = '/'
        }, 2000);
    }
    return (
        <>
            <div className="header_div">
                <div className='container-fluid common_container'>
                    <div className='row  d-flex align-items-center'>
                        <div className='col-6 d-md-block d-lg-none'>
                            <AiOutlineMenuUnfold onClick={showDrawer} size={25} />
                        </div>
                        <div className='col-lg-1 display_none ' role="button" onClick={() => { loggedInType == 'Provider' ? navigate('/provider_dashboard') : loggedInType == 'Facility' ? navigateUrl ? navigate(navigateUrl) : navigate('/dashboard') : loggedInType == 'Patient' ? navigate('/patient_home') : loggedInType == 'Family' ? navigate('/family_home') : navigate('/admin_dashboard') }}>
                            <img src={Logo} alt="" className='logo_width' />
                        </div>
                        <div className='col-lg-9 display_none'>
                            <Menu theme="" mode="horizontal" style={{ border: "none", display: "flex", alignItems: "center" }} >
                                {headerMenu && headerMenu.map((itm, index) => {
                                    let dynName = null
                                    if (providerType == "Provider") {
                                        if (itm?.name == "Assign Members" || itm?.name == "Assigned Tasks" || itm?.name == "Patient") {
                                            return false;
                                        } else {
                                            dynName = itm?.name
                                        }
                                    } else {
                                        if (isSupervisor == 1) {
                                            if (itm?.name == "My Tasks") {
                                                return false;
                                            } else {
                                                dynName = itm?.name
                                            }
                                        } else {
                                            if (itm?.name == "My Tasks" || itm?.name == "Assign Members" || itm?.name == "Bookings") {
                                                if (location?.pathname === "/provider_bookings") {
                                                    navigate('provider_dashboard');
                                                }
                                                return false;
                                            } else {
                                                dynName = itm?.name
                                            }
                                        }
                                    }
                                    return (
                                        <Menu.Item key={index}>
                                            {itm.url == "#" ?
                                                <>
                                                    {isSuperAdmin ?
                                                        <button style={{ width: "100%" }} onClick={(e) => ref.current.handleTopic()} type="submit" className="mx-2 common_btn px-4">
                                                            {itm.name}
                                                        </button>
                                                        :
                                                        <>
                                                            {readWritePermit?.value?.write ?
                                                                <button style={{ width: "100%" }} onClick={(e) => ref.current.handleTopic()} type="submit" className="mx-2 common_btn px-4">
                                                                    {itm.name}
                                                                </button>
                                                                :
                                                                <button style={{ width: "100%" }} onClick={(e) => { }} type="submit" className="mx-2 common_btn px-4">
                                                                    {itm.name}
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                : <Link to={itm.url} onClick={() => { handleCheckUser(dynName) }} style={{ textDecoration: "none", color: activeIndex == itm.url ? "#000" : "#104861" }} >
                                                    <span style={{ fontFamily: "Roboto, sans-serif" }} className='mx-2 medium_font pt-1'>
                                                        {dynName}
                                                    </span>
                                                </Link>}
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        </div>
                        {patient_token &&
                            <div className='col-6 col-lg-1' onClick={() => sosCall()} style={{ cursor: 'pointer', textAlign: 'right' }}>
                                <img style={{ maxHeight: 40 }} src={sos} />
                            </div>
                        }
                        <div className={`col-6 ${patient_token ? 'col-lg-1' : 'col-lg-2'} text-end`}>
                            <Dropdown>
                                <Dropdown.Toggle className='herader__user__icon ' style={{ background: "transparent", border: "none" }}>
                                    <img src={facility_token ? (facilityProfilePic?.includes('https://d141484d0td5s5.cloudfront.net/') || facilityProfilePic?.includes("https://d3kg0hjn28qie3.cloudfront.net/") ? facilityProfilePic : `${fileUrl}${facilityProfilePic}`) : userProfilePic ? fileUrl + userProfilePic : hospital_token ? fileUrl + facilityProfilePic : User} alt="img" className="user_img" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <Dropdown.Item onClick={() => navigate(admin_token ? '/admin_profile' : provider_token ? 'provider-profile' : facility_token ? 'facility-profile' : patient_token ? 'patient-profile' : family_token ? 'family-profile' : hospital_token ? 'hospital_profile' : 'admin_profile')}>
                                        <span style={{ color: "#104861" }}><CgProfile /> Profile</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate('/changepassword')} className='my-3'>
                                        <span style={{ color: "#104861" }}><RiLockPasswordLine /> Change Password</span>
                                    </Dropdown.Item>
                                    <div className='p-3'>
                                        <select className='form-control' value={selectedStatusValue} onChange={changeOnlineStatus}>
                                            <option value="online">Online</option>
                                            <option value="offline">Offline</option>
                                            <option value="busy">Busy</option>
                                            <option value="away">Away</option>
                                        </select>
                                    </div>
                                    <Dropdown.Item onClick={provider_token ? handleProviderLogout : facility_token ? handleFacilityLogout : patient_token ? handlePatientLogout : family_token ? handleFamilyLogout : super_token ? handleSuperAdminLogout : handleHospitalLogout}>
                                        <span style={{ color: "#104861" }}>
                                            <BiLogOut /> Logout
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {licenseAlert < 11 && <LecinseAlert alert={() => setLicenseAlert(11)} expiry={contract_expiry} />}
            </div>
            <Toaster />
            <GoLive ref={ref} />
            {facility_token != null && facility_token != undefined ?
                <Streaming userType={"Facility"} />
                : null
            }
            {provider_token != null && provider_token != undefined ?
                <Streaming userType={"Provider"} />
                : null
            }
            {patient_token != null && patient_token != undefined ?
                <Streaming userType={"Patient"} />
                : null
            }
            {family_token != null && family_token != undefined ?
                <Streaming userType={"Family Member"} />
                : null
            }
            <ChatCalling />
            <Drawer
                className='d-block d-lg-none'
                placement={placement}
                closable={false}
                onClose={() => setOpenSide(false)}
                open={openSide}>
                <div className='container-fluid common_container'>
                    <div className="w-100" style={{ boxShadow: "none" }}>
                        <div className="header_logo my-3">
                            <img src={Logo} alt="" width="10%" />
                            {/* <span className='large_font px-3' style={{ color: "#2b3c95", letterSpacing: "3px" }}>COLONY</span> */}
                        </div>
                        <div className="content_list">
                            {headerMenu && headerMenu.map((itm, index) => {
                                let dynName = null
                                if (providerType == "Provider") {
                                    if (itm?.name == "Assign Members" || itm?.name == "Assigned Tasks" || itm?.name == "Patient") {
                                        return false;
                                    } else {
                                        dynName = itm?.name
                                    }
                                } else {
                                    if (isSupervisor == 1) {
                                        if (itm?.name == "My Tasks") {
                                            return false;
                                        } else {
                                            dynName = itm?.name
                                        }
                                    } else {
                                        if (itm?.name == "My Tasks" || itm?.name == "Assign Members") {
                                            return false;
                                        } else {
                                            dynName = itm?.name
                                        }
                                    }
                                }
                                return (
                                    <React.Fragment key={index}>
                                        {itm.url == "#" ?
                                            <>
                                                {isSuperAdmin ?
                                                    <button onClick={(e) => ref.current.handleTopic()} className="mx-2 common_btn my-3 w-50">
                                                        {itm.name}
                                                    </button>
                                                    :
                                                    <>
                                                        {readWritePermit?.value?.write ?
                                                            <button onClick={(e) => ref.current.handleTopic()} className="mx-2 common_btn my-3 w-50">
                                                                {itm.name}
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { }} className="mx-2 common_btn my-3 w-50">
                                                                {itm.name}
                                                            </button>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <Link to={itm.url} onClick={() => { handleCheckUser(dynName) }} style={{ textDecoration: "none", color: activeIndex == itm.url ? "#000" : "#104861" }} >
                                                <p style={{ fontFamily: "Roboto, sans-serif" }} className='mx-2 medium_font pt-1'>
                                                    {dynName}
                                                </p>
                                            </Link>}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Drawer>
            <Modal title="Permission for SOS Call" confirmLoading={btnLoading} open={SOSCallModal} onOk={() => initialiseSOSCall()} onCancel={() => setSOSCallModal(false)}>
                <p>Are you sure to start SOS Call ?</p>
            </Modal>
        </>
    )
}
export default Header;