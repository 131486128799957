import { baseUrl } from "../../../Constant/url";
import { apiCall } from "../../../api";
import { GET_USER } from "../../type";

// Get profile Data
export const facilityProfile = (data,successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/get`,
        data,
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
            dispatch({
                type: GET_USER,
                payload: res?.data?.data
            })
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// upload media file
export const uploadFacilityProfile = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'multipart/form-data' },
        url: `${baseUrl}facilities/upload_media`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Create Avatr image
export const createFacilityAvatar = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-type': 'application/json' },
        url: `${baseUrl}facilities/create_avatar`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// update profile Data
export const updateFacilityProfile = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/update_facility`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
            dispatch({
                type: GET_USER,
                payload: res?.data?.data
            })
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

/*  update Sub - Admin */
export const updateSubAdminDet = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/update_facility`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res)
        } else {
            failureRes(res)
        }
    } catch (error) {
        failureRes(error)
    }
}

// Change password
export const facilityChangePassword = (data, successRes, failureRes) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem("facility_token")}` },
        url: `${baseUrl}facilities/change_password`,
        data
    }
    const res = await apiCall(config, dispatch);
    try {
        if (res?.data?.status) {
            successRes(res?.data)
        } else {
            failureRes(res?.data)
        }
    } catch (error) {
        failureRes(error)
    }
}