// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8I-mpMCmvtg5KaWDuZfvfw07wx2ihDGg",
  authDomain: "colony-new.firebaseapp.com",
  projectId: "colony-new",
  storageBucket: "colony-new.appspot.com",
  messagingSenderId: "989870192903",
  appId: "1:989870192903:web:ea1af134295638d2dbada2",
  measurementId: "G-J5W5PH1G23"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export let messaging = null;

if (navigator.vendor !== 'Apple Computer, Inc.') {
  messaging = getMessaging(app);
} else {
  console.log("Running on Safari")
}


export const onMessageListener = (navRec) =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        navRec(payload);
      });
    } catch (err) {
      console.error(err)
    }
  });



